<template>
  <v-data-table
    :headers="headers"
    :items="laundryItems"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:item.laundryType="{ item }">
      {{ item.laundryType === 1 ? 'Kg' : 'Kpl' }}
    </template>
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Tuotteet</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Hae..."
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedLaundryItems.laundryName"
                      :label="$t('editLaundryType.laundryName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedLaundryItems.laundryPrice"
                      :label="$t('editLaundryType.laundryPrice')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="editedLaundryItems.laundryType"
                      :items="type"
                      :label="$t('editLaundryType.laundryType')"
                      :placeholder="$t('editLaundryType.laundryType')"
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="editedLaundryItems.laundryState"
                      :items="states"
                      :label="$t('editLaundryType.laundryState')"
                      :placeholder="
                        $t('editLaundryType.laundryAutoCompleteChoise')
                      "
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
  import LaundryTypeService from '@/services/LaundryTypeService.js';

  export default {
    data() {
      return {
        laundryItems: [],
        dialog: false,
        error: null,
        message: null,
        search: '',
        formTitle: 'Muokkaa tuotteen tietoja',
        headers: [
          {
            text: this.$t('showLaundryType.laundryName'),
            value: 'laundryName',
          },
          {
            text: this.$t('showLaundryType.laundryPrice'),
            value: 'laundryPrice',
          },
          {
            text: this.$t('showLaundryType.laundryType'),
            value: 'laundryType',
          },
          {
            text: this.$t('showLaundryType.laundryState'),
            value: 'laundryState',
          },
          {
            text: this.$t('showLaundryType.laundryActions'),
            value: 'actions',
            sortable: false,
          },
        ],
        states: [
          {
            name: this.$t('editLaundryType.laundryStateItems.active'),
            value: 'Käytössä',
          },
          {
            name: this.$t('editLaundryType.laundryStateItems.passive'),
            value: 'Pois käytöstä',
          },
        ],
        editedLaundryItems: {
          laundryName: '',
          laundryState: '',
          laundryPrice: '',
          laundryType: '',
        },
        type: [
          { name: 'Kg', value: 1 },
          { name: 'Kpl', value: 2 },
        ],
      };
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
    },

    async created() {
      const response = await LaundryTypeService.getAllLaundryTypes();
      this.laundryItems = response.data.types;
    },

    methods: {
      editItem(item) {
        this.editedLaundryItems = Object.assign({}, item);
        this.dialog = true;
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedLaundryItems = Object.assign({}, this.laundryItems);
        });
      },

      async save() {
        try {
          let price = String(this.editedLaundryItems.laundryPrice);

          if (price.includes(',')) {
            this.editedLaundryItems.laundryPrice = this.editedLaundryItems.laundryPrice.replace(
              ',',
              '.'
            );
          }
          const response = await LaundryTypeService.saveLaundryTypeModification(
            this.editedLaundryItems
          );

          this.message = response.data.message;
          this.error = response.data.error;
          const types = await LaundryTypeService.getAllLaundryTypes();
          this.laundryItems = types.data.types;
          this.close();
          setTimeout(() => {
            this.message = null;
            this.error = null;
          }, 3000);
        } catch (error) {
          this.close();
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 10000);
        }
      },
    },
  };
</script>
<style></style>
