<template>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="products"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Tuotteet</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Hae..."
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editProductValues.productName"
                      label="Tuotteen nimi"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editProductValues.productPrice"
                      label="Tuotteen maksimi määrä"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editProductValues.productState"
                      :items="productStates"
                      label="Osaston tila"
                      placeholder="Osaston tila"
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import ProductService from '@/services/RentalClothesProductService.js';
import moment from 'moment';
export default {
  data() {
    return {
      products: [],
      dialog: false,
      error: null,
      message: null,
      search: '',

      headers: [
        { text: 'Tuotteen nimi', value: 'productName' },
        { text: 'Tuotteen hinta', value: 'productPrice' },
        { text: 'Tuotteen määrä', value: 'amount' },
        { text: 'Tuotteen tila', value: 'productState' },
        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],
      productStates: [
        { name: 'Käytössä', value: 'Käytössä' },
        {
          name: 'Poistettu käytöstä',
          value: 'Poistettu käytöstä',
        },
      ],
      editProductValues: {
        productName: '',
        productPrice: '',
        productState: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    const response = await ProductService.getAllRentalClothesProducts();
    this.products = response.data.products;
    console.log(this.products);
  },

  methods: {
    editItem(item) {
      this.editProductValues = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editProductValues = Object.assign({}, this.products);
      });
    },

    async save() {
      try {
        this.editProductValues.updatedAt = moment();
        const response = await ProductService.saveRentalClothesProductModification(
          this.editProductValues
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const product = await ProductService.getAllRentalClothesProducts();
        this.products = product.data.products;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style></style>
