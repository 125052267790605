<template>
  <v-data-table
    :headers="headers"
    :items="customers"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Asiakkaat</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Hae..."
          class="mx-4"
        ></v-text-field>

        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.customerName"
                      :label="$t('editCustomer.customerName')"
                    ></v-text-field>
                  </v-col>
                  <v-flex xs12 md12>
                    <v-autocomplete
                      prepend-icon="mdi-adjust"
                      v-model="editedItem.customerGroupId"
                      :items="groupItems"
                      item-text="groupName"
                      item-value="id"
                      :label="$t('addCustomer.customerGroup')"
                      :placeholder="
                        $t('addCustomer.customerAutoCompleteChoise')
                      "
                      required
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-autocomplete
                      prepend-icon="mdi-adjust"
                      v-model="editedItem.customerItems"
                      :items="laundryTypeItems"
                      :item-text="
                        (item) =>
                          item.laundryName + ' - ' + item.laundryPrice + '€'
                      "
                      item-value="id"
                      :label="$t('editCustomer.customerItems')"
                      :placeholder="
                        $t('editCustomer.customerAutoCompleteChoise')
                      "
                      required
                      multiple
                      chips
                    ></v-autocomplete>
                  </v-flex>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editedItem.customerState"
                      :items="states"
                      :label="$t('editCustomer.customerState')"
                      :placeholder="
                        $t('editCustomer.customerAutoCompleteChoise')
                      "
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import CustomerService from '@/services/CustomerService.js';
  import LaundryTypeService from '@/services/LaundryTypeService.js';
  import GroupService from '@/services/GroupService.js';

  export default {
    data() {
      return {
        customers: [],
        laundryTypeItems: [],
        groupItems: '',
        dialog: false,
        error: null,
        message: null,
        search: '',
        formTitle: 'Muokkaa asiakkaan tietoja',
        headers: [
          { text: this.$t('showCustomer.customerName'), value: 'customerName' },
          {
            text: this.$t('showCustomer.customerState'),
            value: 'customerState',
          },
          {
            text: this.$t('showCustomer.customerActions'),
            value: 'actions',
            sortable: false,
          },
        ],
        states: [
          {
            name: this.$t('editCustomer.customerStateItems.active'),
            value: 'Aktiivinen',
          },
          {
            name: this.$t('editCustomer.customerStateItems.passive'),
            value: 'Passiivinen',
          },
        ],
        editedItem: {
          customerName: '',
          customerState: '',
          LaundryType: '',
          customerGroupId: '',
          customerItems: '',
        },
      };
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
    },

    async created() {
      const customers = await CustomerService.getAllCustomers();
      this.customers = customers.data.customers;
      for (let i = 0; i < this.customers.length; i++) {
        this.customers[i].customerItems = this.customers[i].customerItems.split(
          ','
        );
      }

      const laundryTypes = await LaundryTypeService.getAllLaundryTypes();
      this.laundryTypeItems = laundryTypes.data.types;

      for (let i = 0; i < this.laundryTypeItems.length; i++) {
        this.laundryTypeItems[i].id = this.laundryTypeItems[i].id.toString();
      }

      const groups = await GroupService.getAllGroups();
      this.groupItems = groups.data.groups;
    },

    methods: {
      editItem(item) {
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.customers);
        });
      },

      async save() {
        try {
          if (this.editedItem.customerItems) {
            let layndryTypes = '';
            let editLaundryTypes = '';
            for (let i = 0; i < this.editedItem.customerItems.length; i++) {
              layndryTypes += this.editedItem.customerItems[i] + ',';
            }
            editLaundryTypes = layndryTypes.slice(0, -1);
            this.editedItem.customerItems = editLaundryTypes;
          }
          console.log(this.editedItem);
          const response = await CustomerService.saveCustomerModification(
            this.editedItem
          );
          this.message = response.data.message;
          this.error = response.data.error;
          const customers = await CustomerService.getAllCustomers();
          this.customers = customers.data.customers;
          for (let i = 0; i < this.customers.length; i++) {
            this.customers[i].customerItems = this.customers[
              i
            ].customerItems.split(',');
          }

          this.close();
          setTimeout(() => {
            this.message = null;
            this.error = null;
          }, 3000);
        } catch (error) {
          this.close();
          this.error = 'Jokin meni vikaan';
          setTimeout(() => {
            this.error = null;
          });
        }
      },
    },
  };
</script>
<style></style>
