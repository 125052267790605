<template>
  <v-container>
    <v-layout justify-center wrap>
      <v-flex xs12 md6>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedStartDate"
              label="Alku päivä"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportInitials.start"
            :first-day-of-week="1"
            locale="fi-FI"
            @input="menu1 = false"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 md6>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedEndDate"
              label="Loppu päivä"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportInitials.end"
            :first-day-of-week="1"
            locale="fi-FI"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 md6>
        <v-autocomplete
          prepend-icon="mdi-circle-outline"
          v-model="reportInitials.type"
          :items="reportTypes"
          item-text="text"
          item-value="value"
          label="Valitse raportin tyyppi"
          :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
          required
          @change="showReportItems(reportInitials.type)"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12 md6>
        <v-autocomplete
          prepend-icon="mdi-circle-outline"
          v-model="reportInitials.selection"
          :items="reportSelection"
          item-text="name"
          item-value="id"
          :label="selectionLabel"
          :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
          required
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12 text-left>
        <v-btn
          class="mx-0 font-weight-light"
          color="general"
          @click.prevent="showReports"
        >
          Näytä raportti
        </v-btn>

        <v-btn
          class="mx-0 font-weight-light ml-4"
          color="general"
          @click="createPdfCarpets"
          v-if="reportType == 'Carpets'"
        >
          Luo Pdf
        </v-btn>
        <v-btn
          class="mx-0 font-weight-light ml-4"
          color="general"
          @click="createPdf"
          v-if="reportType !== 'Carpets'"
        >
          Luo Pdf
        </v-btn>
      </v-flex>
      <v-flex xs12 md12>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
      </v-flex>

      <!-- Data-table kaikki, paikan ja ryhmän mukaan -->
      <v-flex xs12 md12 v-if="showDataTable">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="responseData"
          item-key="id"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="reportType == 'All'"
        >
          <template v-slot:item.amounts="{ item }">
            <div class="item-amounts">
              <div class='"item-amount"'>
                {{ item.amounts }}
              </div>
              <div class="item-type">
                {{ item.type === 1 ? 'Kg' : 'Kpl' }}
              </div>
            </div>
          </template>
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="body.append">
            <tr class="pink--blak">
              <th><h3>Yhteensä</h3></th>
              <th></th>
              <th></th>
              <th>{{ sumField('priceTotal').toFixed(2) }} €</th>
              <th></th>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td colspan="5">
                <div class="total-items">
                  <div class="total-weight">
                    <div>{{ computedWeight }} kg</div>
                    <div>{{ computedWeightPrice }} €</div>
                  </div>
                  <div class="total-amount">
                    <div>{{ computedAmout }} kpl</div>
                    <div>{{ computedAmountPrice }} €</div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-flex xs12 md12 text-left class="mt-5" v-if="reportType == 'All'"
          ><h4>
            Mattoraportti
          </h4></v-flex
        >
        <v-data-table
          :search="search"
          :headers="headersCarpets"
          :items="carpets"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="reportType == 'All'"
        >
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span> {{ showDate(item.updatedAt) }}</span>
          </template>

          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="body.append">
            <tr class="pink--blak">
              <th><h3>Yhteensä:</h3></th>
              <th></th>

              <th>{{ sumFieldCarpets('carpetPrice').toFixed(2) }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
        <!-- Data-table kaikki pyykkierät poistolla -->
        <v-data-table
          :search="search"
          :headers="headersAllBatches"
          :items="responseData"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="reportType == 'AllBatches'"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ showDate(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
        <!-- Data-table kaikki, paikan ja ryhmän mukaan -->
        <v-data-table
          :search="search"
          :headers="headersCarpets"
          :items="responseData"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="reportType == 'Carpets'"
        >
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span> {{ showDate(item.updatedAt) }}</span>
          </template>

          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="body.append">
            <tr class="pink--blak">
              <th><h3>Yhteensä</h3></th>
              <th>{{}}</th>

              <th>{{ sumFieldCarpets('carpetPrice').toFixed(2) }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <!-- Data-table kaikki, paikan ja ryhmän mukaan -->
      <v-flex xs12 md12 v-if="showDataTable">
        <v-data-table
          :search="search"
          :headers="headersItems"
          :items="responseData"
          item-key="id"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="reportType == 'AllWithGroups'"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="body.append">
            <tr class="pink--blak">
              <th><h3>Yhteensä</h3></th>
              <th></th>
              <th>{{ sumField('priceTotal').toFixed(2) }}</th>
            </tr>
          </template>
        </v-data-table>

        <!-- Dialogi haluatko poistaa? -->
      </v-flex>
      <v-flex xs12 md12 v-if="showDataTable">
        <v-data-table
          :search="search"
          :headers="headersGroup"
          :items="responseData"
          item-key="id"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="reportType == 'Group'"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="body.append">
            <tr class="pink--blak">
              <th><h3>Yhteensä</h3></th>
              <th></th>
              <th>{{ sumField('priceTotal').toFixed(2) }}</th>
            </tr>
          </template>
        </v-data-table>

        <!-- Dialogi haluatko poistaa? -->
      </v-flex>
      <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Haluatko varmasti poistaa pyykkierän?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">En</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Kyllä</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
  import ReportService from '@/services/ReportService';
  import CustomerService from '@/services/CustomerService';
  import GroupService from '@/services/GroupService';
  import LaundryAmountService from '@/services/LaundryAmountService.js';
  import { jsPDF } from 'jspdf';
  import 'jspdf-autotable';

  import moment from 'moment';
  export default {
    data() {
      return {
        headers: [
          { text: 'Asiakas', value: 'customerName' },
          { text: 'Tuote', value: 'laundryName' },
          { text: 'Kilot/kpl', value: 'amounts' },
          { text: 'Hinta Yht', value: 'priceTotal' },
          { text: 'Selite', value: 'text' },
        ],
        headersItems: [
          { text: 'Tuote', value: 'laundryName' },
          { text: 'Kilot', value: 'amounts' },
          { text: 'Hinta Yht', value: 'priceTotal' },
          { text: 'Selite', value: 'text' },
        ],
        headersGroup: [
          { text: 'Paikka', value: 'customerName' },
          { text: 'Tuote', value: 'laundryName' },
          { text: 'Kilot', value: 'amounts' },
          { text: 'Hinta Yht', value: 'priceTotal' },
          { text: 'Selite', value: 'text' },
        ],
        headersAllBatches: [
          { text: 'Asiakas', value: 'customerName' },
          { text: 'Tuote', value: 'laundryName' },
          { text: 'Kilot/kpl', value: 'amounts' },
          { text: 'Pvm', value: 'createdAt' },
          { text: 'Selite', value: 'text' },
          {
            text: 'poista',
            value: 'actions',
            sortable: false,
          },
        ],
        headersCarpets: [
          { text: 'Asiakas', value: 'customerName' },
          { text: 'Koodi', value: 'carpetCode' },
          { text: 'Hinta', value: 'carpetPrice' },
          { text: 'Pvm', value: 'updatedAt' },
        ],
        deleteDialog: false,
        selectionLabel: '',
        reportInitials: {
          start: '',
          end: '',
          selection: '',
        },
        start: '',
        end: '',
        reportType: '',
        reportSelection: [],

        error: false,
        message: false,
        reportTypes: [
          { text: 'Kaikki', value: 'All' },
          { text: 'Paikan mukaan', value: 'Customer' },
          { text: 'Ryhmän mukaan', value: 'Group' },
          { text: 'Kilot ryhmittäin', value: 'AllWithGroups' },
          { text: 'Matot', value: 'Carpets' },
          { text: 'Syötetyt pyykit', value: 'AllLaundryBatches' },
        ],
        menu1: false,
        menu2: false,
        responseData: [],
        search: '',
        showDataTable: false,
        carpets: [],
        separateData: [],
      };
    },
    computed: {
      computedStartDate() {
        return this.formatDate(this.reportInitials.start);
      },
      computedEndDate() {
        return this.formatDate(this.reportInitials.end);
      },
      computedAmout() {
        if (this.separateData.length) {
          if (this.separateData[0].type === 2) {
            return this.separateData[0].amounts;
          }
        }
        if (this.separateData.length > 1) {
          if (this.separateData[1].type === 2) {
            return this.separateData[1].amounts;
          }
        }

        return 0;
      },
      computedWeight() {
        if (this.separateData.length) {
          if (this.separateData[0].type === 1) {
            return this.separateData[0].amounts;
          }
        }
        if (this.separateData.length > 1) {
          if (this.separateData[1].type === 1) {
            return this.separateData[1].amounts;
          }
        }

        return 0;
      },
      computedAmountPrice() {
        if (this.separateData.length) {
          if (this.separateData[0].type === 2) {
            return this.separateData[0].priceTotal;
          }
        }
        if (this.separateData.length > 1) {
          if (this.separateData[1].type === 2) {
            return this.separateData[1].priceTotal;
          }
        }

        return 0;
      },
      computedWeightPrice() {
        if (this.separateData.length) {
          if (this.separateData[0].type === 1) {
            return this.separateData[0].priceTotal;
          }
        }
        if (this.separateData.length > 1) {
          if (this.separateData[1].type === 1) {
            return this.separateData[1].priceTotal;
          }
        }

        return 0;
      },
    },
    watch: {
      deleteDialog(val) {
        val || this.closeDelete();
      },
    },
    methods: {
      sumField(key) {
        // sum data in give key (property)
        return this.responseData.reduce((a, b) => a + (b[key] || 0), 0);
      },
      sumFieldCarpets(key) {
        // sum data in give key (property)
        return this.carpets.reduce((a, b) => a + (b[key] || 0), 0);
      },

      createPdf() {
        const columns = [
          { title: 'Asiakas', dataKey: 'customerName' },
          { title: 'Tuote', dataKey: 'laundryName' },
          { title: 'Kilot', dataKey: 'amounts' },
          {
            title: 'Hinta Yht',
            dataKey: 'priceTotal',
          },
          {
            title: 'Selite',
            dataKey: 'text',
          },
        ];
        const doc = new jsPDF();

        doc.autoTable({
          columns,
          body: this.responseData,
        });

        doc.save('a4.pdf');
      },
      createPdfCarpets() {
        const columns = [
          { text: 'Asiakas', dataKey: 'customerName' },
          { text: 'Koodi', dataKey: 'carpetCode' },
          { text: 'Hinta', dataKey: 'carpetPrice' },
          { text: 'Pvm', dataKey: 'updatedAt' },

          // {
          //   title: 'Hinta Yht',
          //   dataKey: 'priceTotal',
          // },
        ];
        const doc = new jsPDF();

        doc.autoTable({
          columns,
          body: this.responseData,
        });
        //let finalY = doc.lastAutoTable.finalY; // The y position on the page

        doc.save('matot.pdf');
      },
      deleteItem(item) {
        this.deleteId = Object.assign({}, item);
        this.deleteDialog = true;
      },
      async deleteItemConfirm() {
        this.closeDelete();
        try {
          const response = await LaundryAmountService.deleteLaundryBatch(
            this.deleteId.id
          );

          this.message = response.data.message;
          this.error = response.data.error;

          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }

        try {
          const batches = await ReportService.getLaundryReport({
            start: this.start,
            end: this.end,
            type: 'AllLaundryBatches',
          });
          this.responseData = batches.data.report;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      },

      closeDelete() {
        this.deleteDialog = false;
      },

      roundPrice(price) {
        return price.toFixed(2);
      },

      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${year}`;
      },
      showDate(date) {
        if (!date) return null;

        return moment(date).format('DD.MM.YYYY');
      },
      async showReportItems(item) {
        switch (item) {
          case 'All':
            {
              this.reportType = 'All';
              this.selectionLabel = 'Kaikki';
              this.reportSelection = [];
            }
            break;
          case 'Customer':
            {
              this.reportType = 'All';
              this.selectionLabel = 'Valitse paikka...';
              const response = await CustomerService.getAllCustomers();
              this.reportSelection = response.data.customers;
              for (let i = 0; i < this.reportSelection.length; i++) {
                this.reportSelection[i].name = this.reportSelection[
                  i
                ].customerName;
              }
            }
            break;
          case 'Group':
            {
              this.reportType = 'Group';
              this.selectionLabel = 'Valitse ryhmä...';
              const response = await GroupService.getAllGroups();
              this.reportSelection = response.data.groups;
              for (let i = 0; i < this.reportSelection.length; i++) {
                this.reportSelection[i].name = this.reportSelection[
                  i
                ].groupName;
              }
            }
            break;
          case 'AllWithGroups':
            this.reportType = 'AllWithGroups';
            this.selectionLabel = 'Kilot ryhmittäin';
            break;

          case 'AllLaundryBatches':
            this.reportType = 'AllBatches';
            this.selectionLabel = 'Syötetyt pyykit';
            break;
          case 'Carpets':
            {
              this.reportType = 'Carpets';
              this.selectionLabel = 'Matot';
            }
            break;
        }
      },

      async showReports() {
        this.showDataTable = true;
        this.reportInitials.start += ' 00:00:00';
        this.reportInitials.end += ' 23:59:59';
        this.start = this.reportInitials.start;
        this.end = this.reportInitials.end;
        const response = await ReportService.getLaundryReport(
          this.reportInitials
        );
        this.responseData = response.data.report;
        this.separateData = response.data.separateAmount;
        console.log(this.separateData);
        //Jos valitaan kaikki niin näytä myös matot
        if (this.reportType === 'All' || this.reportType === 'Customer') {
          this.carpets = response.data.carpets;
          for (let i = 0; i < this.responseData.length; i++) {
            let priceTotal = parseFloat(this.responseData[i].priceTotal);
            this.responseData[i].priceTotal = priceTotal;
          }
          for (let i = 0; i < this.carpets.length; i++) {
            let carpetPrice = parseFloat(this.carpets[i].carpetPrice);
            this.carpets[i].carpetPrice = carpetPrice;
          }
        } else if (this.reportType === 'Carpets') {
          console.log('tääll 2');
          for (let i = 0; i < this.responseData.length; i++) {
            let carpetPrice = parseFloat(this.responseData[i].carpetPrice);
            this.responseData[i].carpetPrice = carpetPrice;
          }
        } else {
          console.log('tääll 3');
          for (let i = 0; i < this.responseData.length; i++) {
            let priceTotal = parseFloat(this.responseData[i].priceTotal);
            this.responseData[i].priceTotal = priceTotal;
          }
        }
        this.reportInitials.start = '';
        this.reportInitials.end = '';
        this.reportInitials.type = '';
        this.reportInitials.selection = '';
      },
    },
  };
</script>

<style scoped>
  .item-amounts {
    display: flex;
  }

  .total-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-weight: bold;
  }
  .total-weight {
    margin-right: 50px;
  }
</style>
