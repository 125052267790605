import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllClothesToRentalProducts() {
    return Api().get('/api/clothes-rental-products/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getProductItemsByCustomerId(params) {
    console.log(params);
    return Api().get('/api/clothes-rental-products-by-user/', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getAllClothesToRentalProductsByState() {
    return Api().get('/api/clothes-rental-product-state/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveClothesToRentalProductModification(credintials) {
    return Api().post('/api/clothes-rental-product_', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveUserProductModification(credintials) {
    console.log(credintials);
    return Api().post('/api/clothes-rental-product-items_', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addClothesToRentalProduct(credintials) {
    return Api().post('/api/clothes-rental-product/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
