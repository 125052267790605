import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllCarpets() {
    return Api().get('/api/carpets', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveCarpetModification(credintials) {
    return Api().post('/api/carpet_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewCarpet(credintials) {
    return Api().post('/api/carpet/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
