import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllMessages() {
    return Api().get('/api/messages/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewUserMessage(credintials) {
    return Api().post('api/add-new-user-message', credintials, {});
  },
  addNewAdminMessage(credintials) {
    return Api().post('api/add-new-admin-message', credintials, {});
  },
  getAllMessagesUser(params) {
    return Api().get('/api/show-messages/', { params });
  },
  updateMessage(credintials) {
    return Api().post('/api/update-message_/', credintials, {});
  },
};
