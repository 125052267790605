<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>Lisää vuokravaate asiakas</h4></v-flex
              >
              <v-flex xs12 md12>
                <v-text-field
                  label="Asiakkaan nimi"
                  prepend-icon="mdi-home-group"
                  :rules="[rules.required]"
                  v-model="newRentalClothesCustomer.customerName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Asiakasnumero"
                  prepend-icon="mdi-home-group"
                  :rules="[rules.required]"
                  v-model="newRentalClothesCustomer.customerReferenceNumber"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="newRentalClothesCustomer.customerState"
                  :items="customerState"
                  item-text="name"
                  item-value="value"
                  label="Asiakkaan tila"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewRentalClothesCustomer"
                >
                  Luo vuokravaate asiakas
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import RentalClothesCustomerService from '@/services/RentalClothesCustomerService.js';
export default {
  data() {
    return {
      customerState: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        { name: 'Passiivinen', value: 'Passiivinen' },
      ],
      newRentalClothesCustomer: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || this.$t('addGroup.groupRequired'),
      },
    };
  },
  methods: {
    async addNewRentalClothesCustomer() {
      try {
        const response = await RentalClothesCustomerService.addNewRentalClothesCustomer(
          this.newRentalClothesCustomer
        );
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
