import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
const getDefaultState = () => {
  return {
    token: null,
    user: null,
    isAuthenticated: false,
  };
};
export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    getToken: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
  },
  mutations: {
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    logout: ({ commit }) => {
      commit('RESET', '');
    },

    setToken({ commit }, token) {
      commit('setToken', token);
    },

    setUser({ commit }, user) {
      commit('setUser', user);
    },
  },
});
