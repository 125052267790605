<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>
                  Lisää vuokravaate
                </h4></v-flex
              >

              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addClothes.customerId"
                  :items="customers"
                  item-text="customerName"
                  item-value="id"
                  label="Asiakas"
                  placeholder="Valitse..."
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addClothes.clothesProductId"
                  :items="products"
                  item-text="productName"
                  item-value="id"
                  label="Tuotteet"
                  placeholder="Valitse..."
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Määrä"
                  prepend-icon="mdi-account-supervisor"
                  :rules="[rules.required]"
                  v-model="addClothes.clothesAmount"
                  autocomplete="off"
                  required
                />
              </v-flex>

              <v-flex xs12 md12>
                <v-text-field
                  label="Poisto sykli kk"
                  prepend-icon="mdi-account-supervisor"
                  v-model="addClothes.clothesRemovalCycle"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewClothes"
                >
                  Lisää vuokravaate
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import RentalClothesProductService from '@/services/RentalClothesProductService.js';
import RentalClothesCustomerService from '@/services/RentalClothesCustomerService.js';
import RentalClothesService from '@/services/RentalClothesService.js';
export default {
  data() {
    return {
      types: [
        { name: 'Vanhenee', value: 'Vanhenee' },
        { name: 'Ei vanhene', value: 'Ei vanhene' },
      ],
      products: [],
      customers: [],
      addClothes: {
        clothesAmount: '',
      },
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen kenttä',
      },
    };
  },
  async created() {
    try {
      const products = await RentalClothesProductService.getAllRentalClothesProductsActive();
      this.products = products.data.products;
      console.log(this.products);
    } catch (error) {
      this.error = error;
    }
    try {
      const customers = await RentalClothesCustomerService.getAllRentalClothesCustomers();
      this.customers = customers.data.customers;
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async addNewClothes() {
      try {
        const response = await RentalClothesService.addNewRentalClothes(
          this.addClothes
        );

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
