import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllBatches() {
    return Api().get('/api/laundry-batches/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getAllBatchesLimit() {
    return Api().get('/api/laundry-limit/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewLaundryBatch(credintials) {
    return Api().post('/api/laundry/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  deleteLaundryBatch(id) {
    return Api().post(
      '/api/laundry__',
      { id },
      {
        // data: { id: id },

        headers: {
          Authorization: 'Bearer ' + store.getters.getToken,
        },
      }
    );
  },
};
