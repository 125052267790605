var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"hide-default-footer":false,"sort-by":"orders","footer-props":{
          'items-per-page-text': 'Rivit',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.error)?_c('v-alert',{attrs:{"value":true,"dense":"","outlined":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),(_vm.message)?_c('v-alert',{attrs:{"value":true,"dense":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Uudet tilaukset")])],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.showDateAndTime(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [(item.orderState === 'Toimitettu')?_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.showDateAndTime(item.updatedAt)))])]):_vm._e()]}},{key:"item.orderAmountOfDelivered",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{attrs:{"autocomplete":"off","value":item.orderAmountOfDelivered},model:{value:(item.orderAmountOfDelivered),callback:function ($$v) {_vm.$set(item, "orderAmountOfDelivered", $$v)},expression:"item.orderAmountOfDelivered"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }