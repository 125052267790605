import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllRentalClothes() {
    return Api().get('/api/rental-clothes', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getAllRentalClothesDate() {
    return Api().get('/api/rental-clothes-date', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveRentalClothesModification(credintials) {
    return Api().post('/api/rental-clothes_', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewRentalClothes(credintials) {
    console.log(credintials);
    return Api().post('/api/rental-clothes', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  deleteRentalClothes(id) {
    console.log(id);
    return Api().post(
      '/api/rental-clothes__',
      { id },
      {
        headers: {
          Authorization: 'Bearer ' + store.getters.getToken,
        },
        // data: { id: id },
      }
    );
  },
};
