<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12>
        <v-data-table
          :headers="headers"
          :items="orders"
          :hide-default-footer="false"
          sort-by="orders"
          class="elevation-1"
          :loading="isLoading"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
        >
          <template v-slot:top>
            <v-alert :value="true" dense outlined type="error" v-if="error">
              {{ error }}
            </v-alert>
            <v-alert :value="true" dense outlined type="success" v-if="message">
              {{ message }}
            </v-alert>
            <v-toolbar flat>
              <v-toolbar-title>Kaikki tilaukset</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ showDateAndTime(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span v-if="item.orderState === 'Toimitettu'">
              <span> {{ showDateAndTime(item.updatedAt) }}</span>
            </span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';

import ClothesToRentalProductService from '@/services/ClothesToRentalProductService';
import ClothesToRentalDepartmentService from '@/services/ClothesToRentalDepartmentService';
import ClothesToRentalOrderService from '@/services/ClothesToRentalOrderService';
export default {
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'Osasto',
          value: 'ClothestorentalDepartment.departmentName',
        },
        {
          text: 'Tuote',
          value: 'ClothestorentalProduct.productName',
        },
        {
          text: 'Määrä',
          value: 'orderAmountOfOrdered',
        },
        {
          text: 'Toimitettu',
          value: 'orderAmountOfDelivered',
        },
        {
          text: 'Tilaus pvm',
          value: 'createdAt',
        },

        {
          text: 'Toimitus pvm',
          value: 'updatedAt',
        },
        {
          text: 'Tila',
          value: 'orderState',
        },
      ],

      departments: [],
      editAmount: [],
      products: [],
      orders: [],
      message: null,
      error: null,
      rules: {
        required: (value) =>
          !!value || this.$t('addLaundryType.laundryRequired'),
      },
    };
  },

  methods: {
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY');
    },
  },
  async created() {
    try {
      this.isLoading = true;
      const products = await ClothesToRentalProductService.getAllClothesToRentalProducts(
        {}
      );
      this.products = products.data.products;

      const departments = await ClothesToRentalDepartmentService.getAllClothesToRentalDepartments();
      this.departments = departments.data.departments;
      const orders = await ClothesToRentalOrderService.getAllClothesToRentalOrders();
      this.orders = orders.data.orders;
      this.isLoading = false;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.subscriber.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },
};
</script>
