import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllClothesToRentalOrders(params) {
    return Api().get('/api/clothes-rental-order-all-orders/', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //
  getAllClothesToRentalOrdersByUserByState(params) {
    return Api().get('/api/clothes-rental-order-by-state/', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //Näytä tilaajan omat tilaukset
  getAllClothesToRentalOrdersByUser(params) {
    return Api().get('/api/clothes-rental-order-by-user/', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //Näytä kaikki uudet tilaukset
  getAllNewClothesToRentalOrders(params) {
    return Api().get('/api/clothes-rental-all-new-orders/', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //Talleta toimitettu määrä
  saveClothesToRentalOrderModification(credintials) {
    return Api().post('/api/clothes-rental-order_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //Lisää uusi tilaus
  addClothesToRentalOrder(credintials) {
    return Api().post('/api/clothes-rental-order/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  //Poista lisätty tilaus
  deleteClothesToRentalOrder(id) {
    console.log(id);
    return Api().post(
      '/api/clothes-rental-order-delete-order__',
      { id },
      {
        headers: {
          Authorization: 'Bearer ' + store.getters.getToken,
        },
        //data: { id: id },
      }
    );
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
