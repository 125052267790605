<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>
                  Valitse näytettävät tuotteet
                </h4></v-flex
              >

              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addUser.productItems"
                  :items="laundryTypes"
                  item-text="productName"
                  item-value="id"
                  label="Vailitse näytettävät tekstiilit"
                  placeholder="Valitse... "
                  required
                  multiple
                  chips
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addLaundryTypes"
                >
                  Talleta
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ClothesToRentalProductService from '@/services/ClothesToRentalProductService.js';
import UserService from '@/services/UserService.js';
export default {
  data() {
    return {
      laundryTypes: [],
      addUser: {
        productItems: '',
        id: '',
      },
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || 'Pakollinen kenttä',
      },
    };
  },
  async created() {
    try {
      const products = await ClothesToRentalProductService.getAllClothesToRentalProductsByState();
      this.laundryTypes = products.data.products;
      console.log(this.laundryTypes);
      for (let i = 0; i < this.laundryTypes.length; i++) {
        this.laundryTypes[i].id = this.laundryTypes[i].id.toString();
      }
    } catch (error) {
      this.error = error;
    }
    try {
      const productItems = await UserService.getMyOrderItems({
        id: this.$store.state.user.id,
      });
      this.addUser = productItems.data.items;

      //for (let i = 0; i < this.addUser.length; i++) {
      this.addUser.productItems = this.addUser[0].productItems.split(',');
      console.log(this.addUser);
      //}
    } catch (error) {
      this.error = error;
    }
  },

  methods: {
    async addLaundryTypes() {
      try {
        let items = '';
        if (this.addUser.productItems) {
          let productItems = '';
          let editProductItems = '';
          for (let i = 0; i < this.addUser.productItems.length; i++) {
            productItems += this.addUser.productItems[i] + ',';
          }
          editProductItems = productItems.slice(0, -1);
          items = editProductItems;
          this.addUser.id = this.$store.state.user.id;
        }
        console.log(this.addUser);
        const response = await ClothesToRentalProductService.saveUserProductModification(
          {
            productItems: items,
            id: this.$store.state.user.id,
          }
        );
        //this.addCustomer = {};

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
