<template>
  <v-container>
    <v-card class="card">
      <v-row class="mb-3">
        <v-col cols="12" sm="12" md="12">
          <line-chart
            :height="300"
            :width="1000"
            :chart-data="this.datacollection"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card class="card ">
      <v-row class="mb-3 mt-3">
        <v-col cols="12" sm="12" md="12">
          <line-chart
            :height="300"
            :width="1000"
            :chart-data="this.datacollection2"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import LineChart from './LineChart.js';
import ReportService from '@/services/ReportService';
import moment from 'moment';
export default {
  components: {
    LineChart,
  },
  data() {
    return {
      reportData: [],
      chartValues: [],
      chartValues2: [],
      reportData2: [],
      chartdata: {},
      chartdata2: {},
      dates: [],
      dates2: [],
      datacollection: {},
      datacollection2: {},
    };
  },
  async mounted() {
    // const startOfMonth = moment()
    //   .clone()
    //   .startOf('month')
    //   .format('YYYY-MM-DD hh:mm');
    // const endOfMonth = moment()
    //   .clone()
    //   .endOf('month')
    //   .format('YYYY-MM-DD hh:mm');
    const start = moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD hh:mm');

    const end = moment().format('YYYY-MM-DD hh:mm');
    const data = await ReportService.getDataForDashboard({
      start: start,
      end: end,
    });
    this.reportData = data.data.report;

    for (let i = 0; i < this.reportData.length; i++) {
      this.chartValues.push(this.reportData[i].total);
      this.dates.push(this.showDateAndTime(this.reportData[i].createdAt));
    }
    const start2 = moment().format('YYYY-MM-DD 00:00:00');
    const end2 = moment().format('YYYY-MM-DD 23:59:59');
    const data2 = await ReportService.getDataForDashboardGroup({
      start: start2,
      end: end2,
    });
    this.reportData2 = data2.data.report;

    for (let i = 0; i < this.reportData2.length; i++) {
      this.chartValues2.push(this.reportData2[i].total);
      this.dates2.push(this.reportData2[i].laundryName);
    }

    this.datacollection = {
      labels: this.dates,
      datasets: [
        {
          data: this.chartValues,
          label: 'Summa yhteensä',
          borderColor: '#3e95cd',
          fill: false,
          backgroundColor: '#f87979',
        },
      ],
      maintainAspectRatio: true,
      responsive: true,
    };
    this.datacollection2 = {
      labels: this.dates2,
      datasets: [
        {
          data: this.chartValues2,
          label: 'Summa yhteensä',
          borderColor: '#3e95cd',
          fill: false,
          backgroundColor: '#f87979',
        },
      ],
      maintainAspectRatio: true,
      responsive: true,
    };
  },

  methods: {
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY');
    },
  },
};
</script>
