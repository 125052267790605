import Vue from 'vue';
import VueRouter from 'vue-router';
import Users from '../views/Users.vue';
import Store from '@/store';
import notFound from '@/components/helpers/notFound';
import AddGroup from '@/components/laundry/groups/AddGroup';
import ShowGroups from '@/components/laundry/groups/ShowGroups';
import AddUser from '@/components/users/AddUser';
import ChangeUserPassword from '@/components/users/ChangeUserPassword';
import ShowUsers from '@/components/users/ShowUsers';
import ChangePassword from '@/components/users/ChangePassword';
import AddCustomer from '@/components/laundry/customers/AddCustomer';
import ShowCustomers from '@/components/laundry/customers/ShowCustomers';
import AddLaundryType from '@/components/laundry/laundrytypes/AddLaundryType';
import ShowLaundryTypes from '@/components/laundry/laundrytypes/ShowLaundryTypes';

import AddLaundry from '@/components/laundry/addlaundry/AddLaundry';
import showLaundryReports from '@/components/laundry/reports/showReports';
import AddCarpet from '@/components/laundry/carpets/AddCarpet';
import ShowCarpets from '@/components/laundry/carpets/ShowCarpets';
import AddRentalClothesCustomer from '@/components/rentalclothes/customers/AddRentalClothesCustomer';
import ShowRentalClothesCustomers from '@/components/rentalclothes/customers/ShowRentalClothesCustomers';
import addClothesToRentalDepartment from '@/components/clothestorental/departments/AddDepartment';
import showClothesToRentalDepartments from '@/components/clothestorental/departments/ShowDepartments';
import addClothesToRentalProduct from '@/components/clothestorental/products/AddProduct';
import showClothesToRentalProducts from '@/components/clothestorental/products/ShowProducts';
import AddClothesToRentalOrder from '@/components/clothestorental/orders/AddOrder';
import MyItesList from '@/components/clothestorental/orders/MyItemsList';
import ShowMyClothesToRentalOrders from '@/components/clothestorental/orders/ShowMyOrders';
import ShowAllNewClothesToRentalOrders from '@/components/clothestorental/orders/ShowAllNewOrders';
import ShowAllClothesToRentalOrders from '@/components/clothestorental/orders/ShowAllOrders';
import ShowClothesToRentalReportCompareAmounts from '@/components/clothestorental/reports/ShowClothesToRentalReportCompareAmounts';
import ShowClothesToRentalReportDateBetween from '@/components/clothestorental/reports/ShowClothesToRentalReportDateBetween';
import AddRentalClothesProduct from '@/components/rentalclothes/products/AddRentalClothesProduct';
import ShowRentalClothesProducts from '@/components/rentalclothes/products/ShowRentalClothesProducts';
import AddRentalClothes from '@/components/rentalclothes/rentaltotal/AddRentalClothes';
import ShowRentalClothes from '@/components/rentalclothes/rentaltotal/ShowRentalClothes';
import ShowRentalClothesDate from '@/components/rentalclothes/rentaltotal/ShowRentalClothesDate';
import showRentalClothesReports from '@/components/rentalclothes/rentaltotal/ShowReport';
import LaundryDashboard from '@/components/laundry/dashboard/Dashboard';
import AddNewMessageUser from '@/components/clothestorental/messages/AddNewMessageUser';
import AddNewMessageAdmin from '@/components/clothestorental/messages/AddNewMessageAdmin';
import ShowMessages from '@/components/clothestorental/messages/ShowMessages';
Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: notFound,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Laundry" */ '../views/Login'),
  },

  //User routes
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'add-user',
        components: {
          users: AddUser,
        },
      },
      {
        path: 'show-users',
        components: {
          users: ShowUsers,
        },
      },
      {
        path: 'change-password',
        components: {
          users: ChangePassword,
        },
      },
      {
        path: 'change-user-password',
        components: {
          users: ChangeUserPassword,
        },
      },
    ],
  },
  //laundry routes
  {
    path: '/laundry',
    name: 'laundry',
    component: () =>
      import(/* webpackChunkName: "Laundry" */ '../views/Laundry'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'change-password',
        components: {
          laundry: ChangePassword,
        },
      },
      {
        path: 'show-reports',
        components: {
          laundry: showLaundryReports,
        },
      },
      {
        path: 'add-laundry',
        components: {
          laundry: AddLaundry,
        },
      },

      {
        path: 'add-group',
        components: {
          laundry: AddGroup,
        },
      },
      {
        path: 'show-groups',
        components: {
          laundry: ShowGroups,
        },
      },
      {
        path: 'add-customer',
        components: {
          laundry: AddCustomer,
        },
      },
      {
        path: 'show-customers',
        components: {
          laundry: ShowCustomers,
        },
      },
      {
        path: 'add-laundrytype',
        components: {
          laundry: AddLaundryType,
        },
      },
      {
        path: 'show-laundrytypes',
        components: {
          laundry: ShowLaundryTypes,
        },
      },
      {
        path: 'add-carpet',
        components: {
          laundry: AddCarpet,
        },
      },
      {
        path: 'show-carpets',
        components: {
          laundry: ShowCarpets,
        },
      },
      {
        path: 'dashboard',
        components: {
          laundry: LaundryDashboard,
        },
      },
    ],
  },
  //Rental to clothes routes
  {
    path: '/rental-clothes',
    name: 'rental-clothes',
    component: () =>
      import(
        /* webpackChunkName: "RentalClothes" */ '../views/RentalToClothes'
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'change-password',
        components: {
          rentalclothes: ChangePassword,
        },
      },
      {
        path: 'add-rental-customer',
        components: {
          rentalclothes: AddRentalClothesCustomer,
        },
      },
      {
        path: 'show-rental-customers',
        components: {
          rentalclothes: ShowRentalClothesCustomers,
        },
      },
      {
        path: 'add-rental-product',
        components: {
          rentalclothes: AddRentalClothesProduct,
        },
      },
      {
        path: 'show-rental-products',
        components: {
          rentalclothes: ShowRentalClothesProducts,
        },
      },
      {
        path: 'add-rental-clothes',
        components: {
          rentalclothes: AddRentalClothes,
        },
      },
      {
        path: 'show-rental-clothes',
        components: {
          rentalclothes: ShowRentalClothes,
        },
      },
      {
        path: 'show-rental-clothes-date',
        components: {
          rentalclothes: ShowRentalClothesDate,
        },
      },
      {
        path: 'show-report',
        components: {
          rentalclothes: showRentalClothesReports,
        },
      },
    ],
  },
  //Vuokratekstiilit
  {
    path: '/clothes-to-rental',
    name: 'clothes-to-rental',
    component: () =>
      import(
        /* webpackChunkName: "ClothesRental" */ '../views/ClothesToRental'
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'change-password',
        components: {
          clothestorental: ChangePassword,
        },
      },
      {
        path: 'add-clothes-rental-department',
        components: {
          clothestorental: addClothesToRentalDepartment,
        },
      },
      {
        path: 'my-items-order-list',
        components: {
          clothestorental: MyItesList,
        },
      },
      {
        path: 'show-clothes-rental-departments',
        components: {
          clothestorental: showClothesToRentalDepartments,
        },
      },
      {
        path: 'add-clothes-rental-product',
        components: {
          clothestorental: addClothesToRentalProduct,
        },
      },
      {
        path: 'show-clothes-rental-products',
        components: {
          clothestorental: showClothesToRentalProducts,
        },
      },
      {
        path: 'add-clothes-rental-order',
        components: {
          clothestorental: AddClothesToRentalOrder,
        },
      },
      {
        path: 'show-my-clothes-rental-orders',
        components: {
          clothestorental: ShowMyClothesToRentalOrders,
        },
      },
      {
        path: 'show-all-new-clothes-rental-orders',
        components: {
          clothestorental: ShowAllNewClothesToRentalOrders,
        },
      },
      {
        path: 'show-all-clothes-rental-orders',
        components: {
          clothestorental: ShowAllClothesToRentalOrders,
        },
      },
      {
        path: 'clothes-to-rental-show-report-date-between',
        components: {
          clothestorental: ShowClothesToRentalReportDateBetween,
        },
      },
      {
        path: 'clothes-to-rental-show-report-compare-amount-delivered',
        components: {
          clothestorental: ShowClothesToRentalReportCompareAmounts,
        },
      },
      {
        path: 'add-new-message-user',
        components: {
          clothestorental: AddNewMessageUser,
        },
      },
      {
        path: 'add-new-message-admin',
        components: {
          clothestorental: AddNewMessageAdmin,
        },
      },
      {
        path: 'show-messages',
        name: 'show-messages',
        components: {
          clothestorental: ShowMessages,
        },
      },
    ],
  },
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "Laundry" */ '../views/Main'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'change-password',
        components: {
          dashboard: ChangePassword,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Store.state.isAuthenticated) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
