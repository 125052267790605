<template>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="rentalClothes"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Vuokravaatteet</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Hae..."
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">Muokkaa </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItems.clothesAmount"
                      label="Määrä"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItems.clothesRemovalCycle"
                      label="Posito sykli kk"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="550px">
          <v-card>
            <v-card-title class="headline"
              >Haluatko varmasti poistaa vuokravaatteen?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">En</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Kyllä</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.createdAt`]="{ item }">
      <span>
        {{ showDateAndTime(item.createdAt, item.clothesRemovalCycle) }}</span
      >
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import RentalClothesService from '@/services/RentalClothesService.js';
import RentalClothesProductService from '@/services/RentalClothesProductService.js';
import RentalClothesCustomerService from '@/services/RentalClothesCustomerService.js';
import moment from 'moment';
export default {
  data() {
    return {
      deleteDialog: false,
      rentalClothes: [],
      dialog: false,
      error: null,
      message: null,
      products: [],
      customers: [],
      search: '',
      deleteId: '',

      headers: [
        { text: 'Asiakas', value: 'RentalClothesCustomer.customerName' },
        {
          text: 'Tuote',
          value: 'RentalClothesProduct.productName',
        },
        {
          text: 'Määrä',
          value: 'clothesAmount',
        },

        {
          text: 'Poistuu käytöstä',
          value: 'createdAt',
        },
        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],

      editedItems: {
        clothesAmount: '',

        clothesRemovalCycle: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    const clothes = await RentalClothesService.getAllRentalClothes();
    this.rentalClothes = clothes.data.clothes;
    const products = await RentalClothesProductService.getAllRentalClothesProductsActive();
    this.products = products.data.products;
    const customers = await RentalClothesCustomerService.getAllRentalClothesCustomers();
    this.customers = customers.data.customers;
  },

  methods: {
    showDateAndTime(dateTime, removalCycle) {
      let newDate = moment(dateTime).add(removalCycle, 'M');
      return moment(newDate).format('DD.MM.YYYY');
    },

    editItem(item) {
      this.editedItems = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deleteId = Object.assign({}, item);
      this.deleteDialog = true;
    },
    closeDelete() {
      this.deleteDialog = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.rentalClothes);
      });
    },
    async deleteItemConfirm() {
      try {
        const response = await RentalClothesService.deleteRentalClothes(
          this.deleteId.id
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const clothes = await RentalClothesService.getAllRentalClothes();
        this.rentalClothes = clothes.data.clothes;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
      this.closeDelete();
    },
    async save() {
      try {
        const response = await RentalClothesService.saveRentalClothesModification(
          this.editedItems
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const clothes = await RentalClothesService.getAllRentalClothes();
        this.rentalClothes = clothes.data.clothes;

        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
