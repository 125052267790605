<template>
  <v-app>
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex xs12 md8>
          <v-alert :value="true" dense outlined type="error" v-if="error">
            {{ error }}
          </v-alert>
          <v-alert :value="true" dense outlined type="success" v-if="message">
            {{ message }}
          </v-alert>

          <v-form ref="form" lazy-validation>
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12 text-left><h4>Palaute / Viesti</h4></v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Otsikko"
                    prepend-icon=""
                    :rules="titleRules"
                    v-model="messages.messageTitle"
                    autocomplete="off"
                  />
                </v-flex>
                <v-flex xs12 md12>
                  <vue-editor
                    v-model="messages.message"
                    :editorToolbar="customToolbar"
                    placeholder="Kirjoita...."
                    :editorOptions="editorOptions"
                  ></vue-editor>
                </v-flex>
                <v-flex xs12 md12>
                  <v-checkbox
                    label="Lähetä viesti sähköpostitse käyttäjille"
                    @change="emailSelection = !emailSelection"
                    v-model="messages.sendMail"
                  ></v-checkbox>
                </v-flex>
                <v-autocomplete
                  v-if="emailSelection"
                  prepend-icon=""
                  v-model="selected"
                  :items="users"
                  item-text="userEmail"
                  item-value="userEmail"
                  label="Sähköpostin vastaanottajat"
                  placeholder="Valitse..."
                  multiple
                  chips
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple>
                      <v-list-item-content>
                        <v-list-item-title
                          ><v-checkbox
                            v-model="selectAll"
                            label="Valitse kaikki"
                            class="text-center"
                          >
                          </v-checkbox>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
                <v-flex xs12 text-xs-right>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="addNewMessageAdmin"
                  >
                    Lähetä palaute
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import MessageService from '@/services/MessageService';
import UserService from '@/services/UserService';
import { VueEditor } from 'vue2-editor';
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // ['blockquote', 'code-block'],
        // [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: 'rtl' }], // text direction
        //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        // ['clean'], // remove formatting button
      ],
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      messageState: [
        { name: 'Uusi', value: 'Uusi' },
        { name: 'Suljettu', value: 'Suljettu' },
      ],
      messages: {},
      message: null,
      error: null,
      targets: [],
      user: {},
      users: [],
      selected: [],

      emailSelection: false,
      titleRules: [
        (v) => !!v || 'Otsikko on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) || 'Otsikon pitää olla 3 merkkiä tai enemmän',
      ],
      messageRules: [
        (v) => !!v || 'Viesti on pakollinen tieto',
        (v) =>
          (v && v.length >= 3 && v.length < 501) ||
          'Viestin pitää olla vähintään 3 merkkiä tai enintään 500 merkkiä',
      ],
    };
  },
  computed: {
    selectAll: {
      set(val) {
        console.log('täällä');
        this.selected = [];
        if (val) {
          for (let i = 0; i < this.users.length; i++) {
            this.selected.push(this.users[i].userEmail);
          }
        }
      },
      get() {
        return this.selected.length === this.users.length;
      },
    },
  },
  async created() {
    this.user = this.$store.getters.getUser;

    const users = await UserService.getAllUsersByPermissions({
      userPermissions: '3',
    });
    this.users = users.data.users;
    //remove first element from array
    this.users.shift();
  },
  methods: {
    // selectAll() {
    //   this.messages.emails = [];
    //   for (let i = 0; i < this.users.length; i++) {
    //     this.messages.emails.push(this.users[i].userEmail);
    //   }
    // },
    // showEmailSelection() {
    //   this.emailSelection != this.emailSelection;
    // },
    async addNewMessageAdmin() {
      if (this.$refs.form.validate()) {
        this.messages.email = this.selected;
        console.log(this.messages);
        try {
          const response = await MessageService.addNewAdminMessage(
            this.messages
          );
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
<style scoped>
v-select__selection {
  width: 100%;
  justify-content: left;
}
</style>
