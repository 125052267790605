import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllGroups() {
    return Api().get('/api/groups', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveGroupModification(credintials) {
    return Api().post('/api/group_', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewGroup(credintials) {
    return Api().post('/api/group/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
