import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
//import colors from 'vuetify/lib/util/colors';
Vue.config.productionTip = false;
Vue.use(Vuetify);

const opts = {
  iconfont: 'mdi || fas',

  theme: {
    primary: '#cecece',
    secondary: '#1c7514',
    accent: '#9c27b0',
    error: '#f44336',
    warning: '#ffeb3b',
    info: '#2196f3',
    success: '#4caf50',
  },
};

export default new Vuetify(opts);
