<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12>
        <v-form ref="form" lazy-validation>
          <v-container py-0>
            <v-row>
              <v-flex xs12 md12 text-left><h4>Tilaa tuotteet</h4></v-flex>
            </v-row>
            <v-row>
              <v-col xs="12" md="5">
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="orderDepartment"
                  :items="departments"
                  item-text="departmentName"
                  item-value="id"
                  label="Osasto"
                  placeholder="Valitse ..."
                  :rules="required"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-for="(product, index) in products" :key="index">
              <v-col xs="12" md="3">
                <v-checkbox
                  v-model="orderItems"
                  :label="product.productName"
                  :value="product.id"
                  prepend-icon="mdi-circle-medium"
                ></v-checkbox>
              </v-col>
              <v-col xs="12" md="2">
                <v-text-field
                  :label="`Max: ${product.productMaxAmount}`"
                  prepend-icon="mdi-cart"
                  v-model="orderAmount[product.id]"
                  autocomplete="off"
                  :placeholder="`Max: ${product.productMaxAmount}`"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-flex xs2 md2 text-xs-right>
              <v-btn
                class="mx-0 font-weight-light"
                color="general"
                @click.prevent="addOrder"
              >
                Tee tilaus
              </v-btn>
            </v-flex>
          </v-container>
        </v-form>
      </v-flex>
      <v-flex xs12 md12>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-data-table
          :headers="headers"
          :items="orders"
          :hide-default-footer="false"
          sort-by="orders"
          class="elevation-1"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Haluatko varmasti poistaa pyykkierän?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">En</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Kyllä</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import ClothesToRentalProductService from '@/services/ClothesToRentalProductService';
import ClothesToRentalDepartmentService from '@/services/ClothesToRentalDepartmentService';
import ClothesToRentalOrderService from '@/services/ClothesToRentalOrderService';
export default {
  data() {
    return {
      orderItems: [],
      orderAmount: [],
      orderDepartment: '',
      headers: [
        {
          text: 'Osasto',
          value: 'ClothestorentalDepartment.departmentName',
        },
        {
          text: 'Tuote',
          value: 'ClothestorentalProduct.productName',
        },
        {
          text: 'Määrä',
          value: 'orderAmountOfOrdered',
        },
        {
          text: 'Tila',
          value: 'orderState',
        },
        {
          text: 'Poista',
          value: 'actions',
          sortable: false,
        },
      ],

      laundryState: [
        {
          name: this.$t('editLaundryType.laundryStateItems.active'),
          value: 'active',
        },
        {
          name: this.$t('editLaundryType.laundryStateItems.passive'),
          value: 'passive',
        },
      ],
      maxAmount: '',
      deleteDialog: false,
      departments: [],
      products: [],
      addOrders: {},
      orders: [],
      deleteId: '',
      message: null,
      error: null,
      required: [(v) => !!v || 'Pakollinen kenttä'],
      rules: {
        required: (value) =>
          !!value || this.$t('addLaundryType.laundryRequired'),
      },
    };
  },
  async created() {
    try {
      const products = await ClothesToRentalProductService.getProductItemsByCustomerId(
        { id: this.$store.state.user.id }
      );
      this.products = products.data.orderItems;
      this.message = products.data.message;
      this.error = products.error;
      const departments = await ClothesToRentalDepartmentService.getAllClothesToRentalDepartments();
      this.departments = departments.data.departments;
      const orders = await ClothesToRentalOrderService.getAllClothesToRentalOrdersByUserByState(
        {
          orderState: 'Uusi',
          orderSubscriber: this.$store.state.user.id,
        }
      );
      this.orders = orders.data.orders;
      setTimeout(() => {
        this.error = null;
        this.message = null;
      }, 3000);
    } catch (error) {
      this.error = error.subscriber.data.error;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    }
  },

  watch: {
    deleteDialog(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    showMaxValue() {
      this.maxAmount = this.products[
        this.addOrders.orderItem - 1
      ].productMaxAmount;
    },
    deleteItem(item) {
      this.deleteId = Object.assign({}, item);
      this.deleteDialog = true;
    },

    async deleteItemConfirm() {
      try {
        const response = await ClothesToRentalOrderService.deleteClothesToRentalOrder(
          this.deleteId.id
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const orders = await ClothesToRentalOrderService.getAllClothesToRentalOrdersByUserByState(
          {
            orderState: 'Uusi',
            orderSubscriber: this.$store.state.user.id,
          }
        );
        this.orders = orders.data.orders;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
      this.closeDelete();
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    async addOrder() {
      if (this.$refs.form.validate()) {
        try {
          let results = [];
          console.log(this.orderItems);
          console.log(this.orderAmount);
          for (let i = 0; i < this.orderItems.length; i++) {
            this.orderAmount.forEach((index, item) => {
              if (this.orderItems[i] === item) {
                let obj = {
                  orderItem: this.orderItems[i],
                  orderAmountOfOrdered: index,
                  orderSubscriber: this.$store.state.user.id,
                  orderDepartment: this.orderDepartment,
                  orderState: 'Uusi',
                };

                results.push(obj);
              }
            });
          }

          const order = await ClothesToRentalOrderService.addClothesToRentalOrder(
            results
          );

          const orders = await ClothesToRentalOrderService.getAllClothesToRentalOrdersByUserByState(
            {
              orderState: 'Uusi',
              orderSubscriber: this.$store.state.user.id,
            }
          );
          this.orderItems = [];
          this.orderAmount = [];
          this.orders = orders.data.orders;
          this.message = order.data.message;
          this.error = order.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.order.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
