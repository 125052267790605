import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllClothesToRentalOrdersDateBetween(params) {
    return Api().get(
      '/api/clothes-rental-order-all-report-date-between-start-end/',
      {
        params,
        headers: {
          Authorization: 'Bearer ' + store.getters.getToken,
        },
      }
    );
  },
  getAllClothesToRentalOrdersDateBetweenCompare(params) {
    return Api().get('/api/clothes-rental-order-report-compare-amounts/', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  downloadMaterialbankFile(params) {
    return Api().get('/api/clothes-rental-pikaohje', {
      params,
      responseType: 'blob',
    });
  },
};
