<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>{{ $t('addGroup.groupTitle') }}</h4></v-flex
              >
              <v-flex xs12 md12>
                <v-text-field
                  :label="$t('addGroup.groupName')"
                  prepend-icon="mdi-home-group"
                  :rules="[rules.required]"
                  v-model="addGroupValues.groupName"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addGroupValues.groupState"
                  :items="groupState"
                  item-text="name"
                  item-value="value"
                  :label="$t('addGroup.groupState')"
                  :placeholder="$t('addGroup.groupAutoCompleteChoise')"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewGroup"
                >
                  {{ $t('addGroup.groupButtonAdd') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import GroupService from '@/services/GroupService.js';
export default {
  data() {
    return {
      groupState: [
        { name: this.$t('register.state-active'), value: 'Aktiivinen' },
        { name: this.$t('register.state-passive'), value: 'Passiivinen' },
      ],
      addGroupValues: {},
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || this.$t('addGroup.groupRequired'),
      },
    };
  },
  methods: {
    async addNewGroup() {
      try {
        const response = await GroupService.addNewGroup(this.addGroupValues);

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
