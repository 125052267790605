<template>
  <v-container>
    <v-layout justify-center wrap>
      <v-flex xs12 md6>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedStartDate"
              label="Alku päivä"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              no-title
              v-on="on"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportInitials.start"
            :first-day-of-week="1"
            locale="fi-FI"
            @input="menu1 = false"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 md6>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedEndDate"
              label="Loppu päivä"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportInitials.end"
            :first-day-of-week="1"
            locale="fi-FI"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 md6>
        <v-autocomplete
          prepend-icon="mdi-traffic-light"
          v-model="reportInitials.type"
          :items="reportTypes"
          item-text="text"
          item-value="value"
          label="Valitse raportin tyyppi"
          :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
          required
          @change="showReportItems(reportInitials.type)"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12 md6>
        <v-autocomplete
          prepend-icon="mdi-traffic-light"
          v-model="reportInitials.selection"
          :items="reportSelection"
          item-text="name"
          item-value="id"
          :label="selectionLabel"
          :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
          required
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12 text-left>
        <v-btn
          class="mx-0 font-weight-light"
          color="general"
          @click.prevent="showReports"
          v-if="
            reportInitials.type === 'All' ||
              (reportInitials.type === 'Customer' &&
                reportInitials.selection !== '')
          "
        >
          Näytä raportti
        </v-btn>
        <v-btn
          class="mx-0 font-weight-light"
          color="general"
          @click.prevent="showReportsWithPrices"
          v-if="reportInitials.type === 'AllWithPrices'"
        >
          Näytä raportti
        </v-btn>
        <v-btn
          class="mx-0 font-weight-light"
          color="general"
          @click.prevent="showReportsCustomersWithPrices"
          v-if="
            reportInitials.type === 'CustomerWithPrices' &&
              reportInitials.selection !== ''
          "
        >
          Näytä raportti
        </v-btn>
        <v-btn
          class="mx-0 font-weight-light ml-4"
          color="general"
          @click="createPdf"
        >
          Luo Pdf
        </v-btn>
      </v-flex>
      <v-flex xs12 md12>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="responseData"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
          v-if="showDataTable"
        >
          <!-- <template v-slot:[`item.priceTotal`]="{ item }">
            <span> {{ roundPrice(item.priceTotal, 0) }}</span>
          </template> -->

          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 md12 v-if="showSimpleTable">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="responseData"
          item-key="id"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="body.append">
            <tr class="black--text">
              <th>Yhteensä</th>
              <th></th>
              <th></th>
              <th></th>
              <th><h3></h3></th>
              <th>
                <u>{{ sumField('priceTotal').toFixed(2) }}</u>
              </th>
            </tr>
          </template>
        </v-data-table>
        <!-- Dialogi haluatko poistaa? -->
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import ReportService from '@/services/RentalClothesReportService';
import CustomerService from '@/services/RentalClothesCustomerService';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      headers: [
        { text: 'Asiakas', value: 'customerName' },
        { text: 'Tuote', value: 'productName' },
        { text: 'Määrä', value: 'amountTotal' },
        { text: 'Viikot', value: 'weeks' },
        { text: 'Hinta', value: 'productPrice' },
        { text: 'Hinta Yht', value: 'priceTotal' },
      ],
      selectionLabel: '',
      reportInitials: {
        start: '',
        end: '',
        selection: '',
      },
      showDataTable: false,
      showSimpleTable: false,
      reportSelection: [],
      total: 0,

      reportTypes: [
        { text: 'Kaikki', value: 'All' },
        { text: 'Paikan mukaan', value: 'Customer' },
        { text: 'Kaikki hinnat yht', value: 'AllWithPrices' },
        { text: 'Paikan mukaan hinnat yht', value: 'CustomerWithPrices' },
      ],
      menu1: false,
      menu2: false,
      responseData: [],
      search: '',
      results: [],
    };
  },
  computed: {
    computedStartDate() {
      return this.formatDate(this.reportInitials.start);
    },
    computedEndDate() {
      return this.formatDate(this.reportInitials.end);
    },
  },

  methods: {
    sumField(key) {
      // sum data in give key (property)
      return this.responseData.reduce((a, b) => a + (b[key] || 0), 0);
    },
    createPdf() {
      const columns = [
        { title: 'Asiakas', dataKey: 'customerName' },
        { title: 'Tuote', dataKey: 'productName' },
        { title: 'Määrä', dataKey: 'amountTotal' },
        { title: 'Viikot', dataKey: 'weeks' },
        { title: 'Hinta', dataKey: 'productPrice' },
        {
          title: 'Hinta Yht',
          dataKey: 'priceTotal',
        },
      ];
      const doc = new jsPDF();

      doc.autoTable({
        columns,
        body: this.responseData,
      });

      doc.save('a4.pdf');
    },
    roundPrice(price) {
      return price.toFixed(2);
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    async showReportItems(item) {
      switch (item) {
        case 'All':
          {
            this.selectionLabel = 'Kaikki';
            this.reportSelection = [];
          }
          break;
        case 'Customer':
          {
            this.selectionLabel = 'Valitse paikka...';
            const response = await CustomerService.getAllRentalClothesCustomers();
            this.reportSelection = response.data.customers;
            for (let i = 0; i < this.reportSelection.length; i++) {
              this.reportSelection[i].name = this.reportSelection[
                i
              ].customerName;
            }
          }
          break;
        case 'AllWithPrices':
          {
            this.selectionLabel = 'Kaikki';
            this.reportSelection = [];
          }
          break;
        case 'CustomerWithPrices':
          {
            this.selectionLabel = 'Valitse paikka...';
            const response = await CustomerService.getAllRentalClothesCustomers();
            this.reportSelection = response.data.customers;
            for (let i = 0; i < this.reportSelection.length; i++) {
              this.reportSelection[i].name = this.reportSelection[
                i
              ].customerName;
            }
          }
          break;
      }
    },

    async showReports() {
      this.reportInitials.start += ' 00:00:00';
      this.reportInitials.end += ' 23:59:59';
      const response = await ReportService.getRentalClothesReport(
        this.reportInitials
      );
      this.showDataTable = true;
      this.showSimpleTable = false;
      this.responseData = response.data.report;

      this.reportInitials.start = '';
      this.reportInitials.end = '';
      this.reportInitials.type = '';
      this.reportInitials.selection = '';
    },
    async showReportsWithPrices() {
      this.reportInitials.start += ' 00:00:00';
      this.reportInitials.end += ' 23:59:59';
      const response = await ReportService.getRentalClothesReport(
        this.reportInitials
      );
      this.showDataTable = false;
      this.showSimpleTable = true;
      this.responseData = response.data.report;
      for (let i = 0; i < this.responseData.length; i++) {
        let priceTotal = parseFloat(this.responseData[i].priceTotal);

        this.responseData[i].priceTotal = priceTotal;
      }

      this.reportInitials.start = '';
      this.reportInitials.end = '';
      this.reportInitials.type = '';
      this.reportInitials.selection = '';
    },
    async showReportsCustomersWithPrices() {
      this.reportInitials.start += ' 00:00:00';
      this.reportInitials.end += ' 23:59:59';
      const response = await ReportService.getRentalClothesReport(
        this.reportInitials
      );
      this.showDataTable = false;
      this.showSimpleTable = true;
      this.responseData.total = 0;
      this.responseData = response.data.report;

      for (let i = 0; i < this.responseData.length; i++) {
        let priceTotal = parseFloat(this.responseData[i].priceTotal);

        this.responseData[i].priceTotal = priceTotal;
      }

      this.reportInitials.start = '';
      this.reportInitials.end = '';
      this.reportInitials.type = '';
      this.reportInitials.selection = '';
    },
  },
};
</script>
