var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Alku päivä","prepend-icon":"mdi-calendar","no-title":"","autocomplete":"off"},model:{value:(_vm.computedStartDate),callback:function ($$v) {_vm.computedStartDate=$$v},expression:"computedStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"locale":"fi-FI","no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.reportInitials.start),callback:function ($$v) {_vm.$set(_vm.reportInitials, "start", $$v)},expression:"reportInitials.start"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Loppu päivä","prepend-icon":"mdi-calendar","autocomplete":"off"},model:{value:(_vm.computedEndDate),callback:function ($$v) {_vm.computedEndDate=$$v},expression:"computedEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"locale":"fi-FI","no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.reportInitials.end),callback:function ($$v) {_vm.$set(_vm.reportInitials, "end", $$v)},expression:"reportInitials.end"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-traffic-light","items":_vm.reportTypes,"item-text":"text","item-value":"value","label":"Valitse raportin tyyppi","placeholder":_vm.$t('addLaundry.laundryAutoCompleteChoise'),"required":""},on:{"change":function($event){return _vm.showReportItems(_vm.reportInitials.type)}},model:{value:(_vm.reportInitials.type),callback:function ($$v) {_vm.$set(_vm.reportInitials, "type", $$v)},expression:"reportInitials.type"}})],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-traffic-light","items":_vm.reportSelection,"item-text":"name","item-value":"id","label":_vm.selectionLabel,"placeholder":_vm.$t('addLaundry.laundryAutoCompleteChoise'),"required":""},model:{value:(_vm.reportInitials.selection),callback:function ($$v) {_vm.$set(_vm.reportInitials, "selection", $$v)},expression:"reportInitials.selection"}})],1),_c('v-flex',{attrs:{"xs12":"","text-left":""}},[(
          _vm.reportInitials.type === 'All' ||
            (_vm.reportInitials.type === 'Customer' &&
              _vm.reportInitials.selection !== '')
        )?_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"general"},on:{"click":function($event){$event.preventDefault();return _vm.showReports.apply(null, arguments)}}},[_vm._v(" Näytä raportti ")]):_vm._e(),(_vm.reportInitials.type === 'AllWithPrices')?_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"general"},on:{"click":function($event){$event.preventDefault();return _vm.showReportsWithPrices.apply(null, arguments)}}},[_vm._v(" Näytä raportti ")]):_vm._e(),(
          _vm.reportInitials.type === 'CustomerWithPrices' &&
            _vm.reportInitials.selection !== ''
        )?_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"general"},on:{"click":function($event){$event.preventDefault();return _vm.showReportsCustomersWithPrices.apply(null, arguments)}}},[_vm._v(" Näytä raportti ")]):_vm._e(),_c('v-btn',{staticClass:"mx-0 font-weight-light ml-4",attrs:{"color":"general"},on:{"click":_vm.createPdf}},[_vm._v(" Luo Pdf ")])],1),_c('v-flex',{attrs:{"xs12":"","md12":""}},[(_vm.showDataTable)?_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.responseData,"sort-by":"batches","footer-props":{
          'items-per-page-text': 'Rivit',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Hae..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}],null,false,3410961356)}):_vm._e()],1),(_vm.showSimpleTable)?_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.responseData,"item-key":"id","sort-by":"batches","footer-props":{
          'items-per-page-text': 'Rivit',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Hae..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}],null,false,3410961356)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"black--text"},[_c('th',[_vm._v("Yhteensä")]),_c('th'),_c('th'),_c('th'),_c('th',[_c('h3')]),_c('th',[_c('u',[_vm._v(_vm._s(_vm.sumField('priceTotal').toFixed(2)))])])])])],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }