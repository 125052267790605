<template>
  <v-data-table
    :headers="headers"
    :items="carpets"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Matot</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Hae..."
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-traffic-light"
                      v-model="editCarpetValues.carpetCustomerId"
                      :items="customers"
                      item-text="customerName"
                      item-value="id"
                      label="Asiakas"
                      placeholder="Valitse"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-traffic-light"
                      v-model="editCarpetValues.carpetHandlerId"
                      :items="users"
                      item-text="userFirstName"
                      item-value="id"
                      label="Käsittelijä"
                      placeholder="Valitse"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Mattokoodi"
                      prepend-icon="mdi-home-group"
                      v-model="editCarpetValues.carpetCode"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Hinta"
                      prepend-icon="mdi-home-group"
                      v-model="editCarpetValues.carpetPrice"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-traffic-light"
                      v-model="editCarpetValues.carpetState"
                      :items="carpetState"
                      item-text="name"
                      item-value="id"
                      label="Tila"
                      placeholder="Valitse"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Kommentti"
                      prepend-icon="mdi-comment-text-outline"
                      v-model="editCarpetValues.carpetText"
                      autocomplete="off"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template
      v-for="header in headers.filter((header) =>
        header.hasOwnProperty('formatter')
      )"
      v-slot:[`item.${header.value}`]="{ headers, value }"
    >
      {{ header.formatter(value) }}
    </template>
  </v-data-table>
</template>
<script>
import CarpetService from '@/services/CarpetService.js';
import UserService from '@/services/UserService.js';
import CustomerService from '@/services/CustomerService.js';

export default {
  data() {
    return {
      carpets: [],
      customers: [],
      users: [],
      dialog: false,
      error: null,
      message: null,
      search: '',
      carpetState: [
        { name: 'Lähetetty pesuun', value: 'Lähetetty pesuun' },
        {
          name: 'Vastaanotettu pesusta',
          value: 'Vastaanotettu pesusta',
        },
      ],
      formTitle: 'Muokkaa maton tietoja',
      headers: [
        { text: 'Asiakas', value: 'Customer.customerName' },
        { text: 'Pvm', value: 'createdAt', formatter: this.splitDate },
        { text: 'Tila', value: 'carpetState' },
        { text: 'Käsittelijä', value: 'User.userFirstName' },
        { text: 'Koodi', value: 'carpetCode' },
        { text: 'Kommentti', value: 'carpetText' },
        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],

      editCarpetValues: {
        carpetCustomerId: '',
        carpetHandlerId: '',
        carpetCode: '',
        carpetText: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    const carpets = await CarpetService.getAllCarpets();
    this.carpets = carpets.data.carpets;
    const users = await UserService.getAllUsersByPermissionLaundry();
    this.users = users.data.users;
    const customers = await CustomerService.getAllCustomers();
    this.customers = customers.data.customers;
  },

  methods: {
    splitDate(value) {
      let date = value.substring(0, 10);
      let dateArray = date.split('-');
      return dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0];
    },

    editItem(item) {
      this.editCarpetValues = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editCarpetValues = Object.assign({}, this.groups);
      });
    },

    async save() {
      try {
        let price = String(this.editCarpetValues.carpetPrice);
        if (price.includes(',')) {
          this.editCarpetValues.carpetPrice = this.editCarpetValues.carpetPrice.replace(
            ',',
            '.'
          );
        }
        const response = await CarpetService.saveCarpetModification(
          this.editCarpetValues
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const carpets = await CarpetService.getAllCarpets();
        this.carpets = carpets.data.carpets;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
