<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left><h4>Lisää matto pesuun</h4></v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  
                  v-model="addCarpetValues.carpetCustomerId"
                  :items="customers"
                  item-text="customerName"
                  item-value="id"
                  label="Asiakas"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  
                  v-model="addCarpetValues.carpetHandlerId"
                  :items="users"
                  item-text="userFirstName"
                  item-value="id"
                  label="Käsittelijä"
                  placeholder="Valitse"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  label="Mattokoodi"
                 
                  :rules="[rules.required]"
                  v-model="addCarpetValues.carpetCode"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 md12>
                <v-textarea
                  label="Lisätietoa"
                  
                  v-model="addCarpetValues.carpetText"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewCarpet"
                >
                  Lisää matto
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CarpetService from '@/services/CarpetService.js';
import CustomerService from '@/services/CustomerService.js';
import UserService from '@/services/UserService.js';
export default {
  data() {
    return {
      carpetState: [
        { name: 'Lähetetty pesuun', value: 'Lähetetty pesuun' },
        {
          name: 'Vastaanotettu pesusta',
          value: 'Vastaanotettu pesusta',
        },
      ],
      addCarpetValues: {},
      customers: [],
      users: [],
      message: null,
      error: null,
      rules: {
        required: (value) => !!value || this.$t('addGroup.groupRequired'),
      },
    };
  },
  async created() {
    try {
      const customers = await CustomerService.getAllCustomers();
      this.customers = customers.data.customers;
    } catch (error) {
      this.error = error;
    }
    try {
      const users = await UserService.getAllUsersByPermissionLaundry();
      this.users = users.data.users;
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
    async addNewCarpet() {
      try {
        const response = await CarpetService.addNewCarpet(this.addCarpetValues);

        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
