import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllCustomers() {
    return Api().get('/api/customers/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getCustomersByGroupId(id) {
    return Api().post('/api/customer-groups/', id, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveCustomerModification(credintials) {
    return Api().post('/api/customer_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  addNewCustomer(credintials) {
    return Api().post('/api/customer/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
