import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllRentalClothesProducts() {
    return Api().get('/api/rental-clothes-products', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getAllRentalClothesProductsActive() {
    return Api().get('/api/rental-clothes-products-active', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveRentalClothesProductModification(credintials) {
    console.log(credintials);
    return Api().post('/api/rental-clothes-product_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewRentalClothesProduct(credintials) {
    console.log(credintials);
    return Api().post('/api/rental-clothes-product/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
