import Api from '@/services/Api';
import store from '@/store';

export default {
  getLaundryReport(params) {
    return Api().post('/api/customer-report', params, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  getDataForDashboard(params) {
    return Api().get('/api/laundry-dashboard', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getDataForDashboardGroup(params) {
    return Api().get('/api/laundry-dashboard-group', {
      params,
    });
  },
};
