<template>
  <v-data-table
    :headers="headers"
    :items="departments"
    sort-by="departmets"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Osastot</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editDepartmentValues.departmentName"
                      label="Osaston nimi"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editDepartmentValues.departmentState"
                      :items="departmentStates"
                      label="Osaston tila"
                      placeholder="Osaston tila"
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import DepartmentService from '@/services/ClothesToRentalDepartmentService.js';

export default {
  data() {
    return {
      departments: [],
      dialog: false,
      error: null,
      message: null,

      headers: [
        { text: 'Osaston nimi', value: 'departmentName' },
        { text: 'Osaston tila', value: 'departmentState' },
        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],
      departmentStates: [
        { name: 'Aktiivinen', value: 'active' },
        {
          name: 'Passiivinen',
          value: 'passive',
        },
      ],
      editDepartmentValues: {
        departmentName: '',
        departmentState: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    const response = await DepartmentService.getAllClothesToRentalDepartments();
    this.departments = response.data.departments;
  },

  methods: {
    editItem(item) {
      this.editDepartmentValues = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editDepartmentValues = Object.assign({}, this.departmets);
      });
    },

    async save() {
      try {
        const response = await DepartmentService.saveClothesToRentalDepartmentModification(
          this.editDepartmentValues
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const department = await DepartmentService.getAllClothesToRentalDepartments();
        this.departments = department.data.departments;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
