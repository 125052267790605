<template>
  <v-container>
    <v-layout justify-center wrap>
      <v-flex xs12 md6>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedStartDate"
              label="Alku päivä"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              no-title
              v-on="on"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportInitials.start"
            :first-day-of-week="1"
            locale="fi-FI"
            @input="menu1 = false"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 md6>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedEndDate"
              label="Loppu päivä"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              autocomplete="off"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="reportInitials.end"
            :first-day-of-week="1"
            locale="fi-FI"
            no-title
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex xs12 text-left>
        <v-btn
          class="mx-0 font-weight-light"
          color="general"
          @click.prevent="showReports"
        >
          Näytä raportti
        </v-btn>
      </v-flex>
      <v-flex xs12 md12 v-if="showData">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="orders"
          sort-by="batches"
          class="elevation-1 mt-5"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Hae..."
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span> {{ showDateAndTime(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span v-if="item.orderState === 'Toimitettu'">
              <span> {{ showDateAndTime(item.updatedAt) }}</span>
            </span>
          </template>
          <template v-slot:[`item.difference`]="{ item }">
            <v-chip
              :color="compareDifference(item.ordered, item.delivered)"
              dark
            >
              {{ compareDifferenceValues(item.ordered, item.delivered) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import ClothesToRentalReportsService from '@/services/ClothesToRentalReportsService';
import moment from 'moment';
export default {
  data() {
    return {
      headers: [
        { text: 'Tuote', value: 'ClothestorentalProduct.productName' },
        { text: 'Tilattu', value: 'ordered' },
        { text: 'Toimitettu', value: 'delivered' },
        { text: 'Ero', value: 'difference' },
      ],

      reportInitials: {
        // start: new Date().toISOString().substr(0, 10),
        // end: new Date().toISOString().substr(0, 10),
        start: '',
        end: '',
        reportType: '',
        selection: '',
      },
      reportSelection: [],
      showData: false,

      menu1: false,
      menu2: false,
      orders: [],
      search: '',
    };
  },
  computed: {
    computedStartDate() {
      return this.formatDate(this.reportInitials.start);
    },
    computedEndDate() {
      return this.formatDate(this.reportInitials.end);
    },
  },

  methods: {
    showDateAndTime(dateTime) {
      return moment(dateTime).format('DD.MM.YYYY');
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    compareDifference(ordered, delivered) {
      if (delivered > ordered) {
        return 'blue';
      } else {
        return 'green';
      }
    },
    compareDifferenceValues(ordered, delivered) {
      return delivered - ordered;
    },

    async showReports() {
      this.reportInitials.start += ' 00:00:00';
      this.reportInitials.end += ' 23:59:59';
      console.log(this.reportInitials);
      const response = await ClothesToRentalReportsService.getAllClothesToRentalOrdersDateBetweenCompare(
        this.reportInitials
      );
      this.orders = response.data.orders;

      this.showData = true;
      this.reportInitials.start = '';
      this.reportInitials.end = '';
    },
  },
};
</script>
