<template>
  <v-app>
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex xs12 md8>
          <v-alert :value="true" dense outlined type="error" v-if="error">
            {{ error }}
          </v-alert>
          <v-alert :value="true" dense outlined type="success" v-if="message">
            {{ message }}
          </v-alert>

          <v-form ref="form" lazy-validation enctype="multipart/form-data">
            <v-container py-0>
              <v-layout wrap>
                <v-flex xs12 md12 text-left><h4>Palaute / Viesti</h4></v-flex>
                <v-flex xs12 md12>
                  <v-text-field
                    label="Otsikko"
                    prepend-icon=""
                    :rules="titleRules"
                    v-model="messages.messageTitle"
                    autocomplete="off"
                  />
                </v-flex>
                <v-flex xs12 md12>
                  <vue-editor
                    v-model="messages.message"
                    :editorToolbar="customToolbar"
                    placeholder="Kirjoita..."
                    :editorOptions="editorOptions"
                  ></vue-editor>
                </v-flex>
                <v-flex xs12 md6>
                  <v-file-input
                    label="Valitse tiedosto"
                    v-model="selectedFile"
                  />
                </v-flex>
                <v-flex xs12 text-xs-right>
                  <v-btn
                    class="mx-0 font-weight-light"
                    color="general"
                    @click="addNewMessageUser"
                  >
                    Lähetä palaute
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import MessageService from '@/services/MessageService';
import { VueEditor } from 'vue2-editor';
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // ['blockquote', 'code-block'],
        // [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: 'rtl' }], // text direction
        //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        // ['clean'], // remove formatting button
      ],
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      messageState: [
        { name: 'Uusi', value: 'Uusi' },
        { name: 'Suljettu', value: 'Suljettu' },
      ],
      messages: {},
      message: null,
      error: null,
      targets: [],
      user: {},
      selectedFile: null,
      titleRules: [
        (v) => !!v || 'Otsikko on pakollinen tieto',
        (v) =>
          (v && v.length >= 3) || 'Otsikon pitää olla 3 merkkiä tai enemmän',
      ],
      messageRules: [
        (v) => !!v || 'Viesti on pakollinen tieto',
        (v) =>
          (v && v.length >= 3 && v.length < 501) ||
          'Viestin pitää olla vähintään 3 merkkiä tai enintään 500 merkkiä',
      ],
    };
  },
  async created() {
    this.user = this.$store.getters.getUser;
  },
  methods: {
    async addNewMessageUser() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        if (this.selectedFile) {
          formData.append('file', this.selectedFile, this.selectedFile.name);
        } else {
          formData.append('noFile', true);
        }
        formData.append('messageTitle', this.messages.messageTitle);
        formData.append('message', this.messages.message);
        formData.append('email', this.user.userEmail);

        try {
          // this.messages.email = this.user.userEmail;
          const response = await MessageService.addNewUserMessage(formData);
          this.message = response.data.message;
          this.error = response.error;
          this.$refs.form.reset();
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      }
    },
  },
};
</script>
