var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Alku päivä","prepend-icon":"mdi-calendar","no-title":"","autocomplete":"off"},model:{value:(_vm.computedStartDate),callback:function ($$v) {_vm.computedStartDate=$$v},expression:"computedStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"locale":"fi-FI","no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.reportInitials.start),callback:function ($$v) {_vm.$set(_vm.reportInitials, "start", $$v)},expression:"reportInitials.start"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Loppu päivä","prepend-icon":"mdi-calendar","autocomplete":"off"},model:{value:(_vm.computedEndDate),callback:function ($$v) {_vm.computedEndDate=$$v},expression:"computedEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"locale":"fi-FI","no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.reportInitials.end),callback:function ($$v) {_vm.$set(_vm.reportInitials, "end", $$v)},expression:"reportInitials.end"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('v-btn',{staticClass:"mx-0 font-weight-light",attrs:{"color":"general"},on:{"click":function($event){$event.preventDefault();return _vm.showReports.apply(null, arguments)}}},[_vm._v(" Näytä raportti ")])],1),(_vm.showData)?_c('v-flex',{attrs:{"xs12":"","md12":""}},[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.orders,"sort-by":"batches","loading":"true","footer-props":{
          'items-per-page-text': 'Rivit',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Hae..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.showDateAndTime(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [(item.orderState === 'Toimitettu')?_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.showDateAndTime(item.updatedAt)))])]):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }