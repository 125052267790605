<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>
                  {{ $t('addCustomer.customerTitle') }}
                </h4></v-flex
              >
              <v-flex xs12 md12>
                <v-text-field
                  :label="$t('addCustomer.customerName')"
                  prepend-icon="mdi-adjust"
                  :rules="[rules.required]"
                  v-model="addCustomer.customerName"
                  autocomplete="off"
                  required
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-adjust"
                  v-model="addCustomer.customerGroup"
                  :items="customerGroups"
                  item-text="groupName"
                  item-value="id"
                  :label="$t('addCustomer.customerGroup')"
                  :placeholder="$t('addCustomer.customerAutoCompleteChoise')"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-adjust"
                  v-model="addCustomer.customerLaundryTypes"
                  :items="laundryTypes"
                  item-text="laundryName"
                  item-value="id"
                  :label="$t('addCustomer.customerItems')"
                  :placeholder="$t('addCustomer.customerAutoCompleteChoise')"
                  required
                  multiple
                  chips
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addCustomer.customerState"
                  :items="customerState"
                  item-text="name"
                  item-value="value"
                  :label="$t('addCustomer.customerState')"
                  :placeholder="$t('addCustomer.customerAutoCompleteChoise')"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewCustomer"
                >
                  {{ $t('addCustomer.customerButtonAdd') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import CustomerService from '@/services/CustomerService.js';
  import GroupService from '@/services/GroupService.js';
  import LaundryTypeService from '@/services/LaundryTypeService.js';
  export default {
    data() {
      return {
        customerState: [
          { name: this.$t('register.state-active'), value: 'Aktiivinen' },
          { name: this.$t('register.state-passive'), value: 'Passiivinen' },
        ],
        laundryTypes: [],
        customerGroups: [],
        addCustomer: {},
        message: null,
        error: null,
        rules: {
          required: (value) =>
            !!value || this.$t('addCustomer.customerRequired'),
        },
      };
    },
    async created() {
      try {
        const response = await GroupService.getAllGroups();
        this.customerGroups = response.data.groups;
      } catch (error) {
        this.error = error;
      }
      try {
        const response = await LaundryTypeService.getAllLaundryTypes();
        this.laundryTypes = response.data.types;
      } catch (error) {
        this.error = error;
      }
    },

    methods: {
      async addNewCustomer() {
        try {
          if (this.addCustomer.customerLaundryTypes) {
            let layndryTypes = '';
            let editLaundryTypes = '';
            for (
              let i = 0;
              i < this.addCustomer.customerLaundryTypes.length;
              i++
            ) {
              layndryTypes += this.addCustomer.customerLaundryTypes[i] + ',';
            }
            editLaundryTypes = layndryTypes.slice(0, -1);
            this.addCustomer.customerLaundryTypes = editLaundryTypes;
          }
          const response = await CustomerService.addNewCustomer(
            this.addCustomer
          );
          this.addCustomer = {};

          this.message = response.data.message;
          this.error = response.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      },
    },
  };
</script>
