import Api from '@/services/Api';
import store from '@/store';

export default {
  getRentalClothesReport(params) {
    return Api().get('/api/rental-clothes-report', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};
