import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllLaundryTypes() {
    return Api().get('/api/laundrytypes/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getLaundryTypeById(id) {
    console.log(id);
    return Api().post('/api/laundrytypeid/', id, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getLaundryTypesByCustomerId(id) {
    return Api().post('/api/laundrytypes/', id, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  saveLaundryTypeModification(credintials) {
    return Api().post('/api/laundrytype_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewLaundryType(credintials) {
    return Api().post('/api/laundrytype/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
