<template>
  <v-container>
    <v-layout justify-center wrap>
      <v-flex xs12 md12>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
      </v-flex>
      <v-flex xs12 md6>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>{{ $t('addLaundry.laundryTitle') }}</h4></v-flex
              >
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-circle-outline"
                  v-model="addLaundry.laundryGroupId"
                  :items="laundryGroups"
                  item-text="groupName"
                  item-value="id"
                  :label="$t('addLaundry.laundryGroup')"
                  :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
                  required
                  @change="showCustomers()"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-circle-outline"
                  v-model="addLaundry.laundryCustomerId"
                  :items="laundryCustomers"
                  item-text="customerName"
                  item-value="id"
                  :label="$t('addLaundry.laundryCustomer')"
                  :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
                  required
                  @change="showLaundryTypes()"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-autocomplete
                  prepend-icon="mdi-circle-outline"
                  v-model="addLaundry.laundryTypeId"
                  :items="laundryTypes"
                  item-text="laundryName"
                  item-value="id"
                  :label="$t('addLaundry.laundryItems')"
                  :placeholder="$t('addLaundry.laundryAutoCompleteChoise')"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  prepend-icon="mdi-circle-outline"
                  :label="$t('addLaundry.laundryAmounts')"
                  :rules="[rules.required]"
                  v-model="addLaundry.laundryAmount"
                  autocomplete="off"
                  required
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-textarea
                  :label="$t('addLaundry.laundryText')"
                  prepend-icon="mdi-circle-outline"
                  v-model="addLaundry.laundryText"
                  autocomplete="off"
                />
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewLaundryBatch"
                >
                  {{ $t('addLaundry.laundryButtonAdd') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
      <v-flex xs12 md6>
        <v-data-table
          :headers="headers"
          :items="batches"
          :hide-default-footer="true"
          sort-by="batches"
          class="elevation-1"
          :footer-props="{
            'items-per-page-text': 'Rivit',
          }"
        >
          <template v-slot:item.amount="{ item }">
            {{ item.amount }}
            {{ item.type === 1 ? 'Kg' : 'Kpl' }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Haluatko varmasti poistaa pyykkierän?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">En</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Kyllä</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
  import GroupService from '@/services/GroupService.js';
  import CustomerService from '@/services/CustomerService.js';
  import LaundryTypeService from '@/services/LaundryTypeService.js';
  import LaundryAmountService from '@/services/LaundryAmountService.js';
  export default {
    data() {
      return {
        headers: [
          {
            text: this.$t('addLaundry.laundryCustomer'),
            value: 'Customer.customerName',
          },
          {
            text: this.$t('addLaundry.laundryItem'),
            value: 'LaundryType.laundryName',
          },
          {
            text: this.$t('addLaundry.laundryAmounts'),
            value: 'amount',
          },
          {
            text: this.$t('addLaundry.laundryActions'),
            value: 'actions',
            sortable: false,
          },
        ],

        laundryState: [
          {
            name: this.$t('editLaundryType.laundryStateItems.active'),
            value: 'active',
          },
          {
            name: this.$t('editLaundryType.laundryStateItems.passive'),
            value: 'passive',
          },
        ],
        deleteDialog: false,
        batches: [],
        laundryGroups: [],
        laundryCustomers: [],
        laundryTypes: [],
        addLaundry: {},
        deleteId: '',
        message: null,
        error: null,
        rules: {
          required: (value) =>
            !!value || this.$t('addLaundryType.laundryRequired'),
        },
      };
    },
    async created() {
      try {
        const response = await GroupService.getAllGroups();
        this.laundryGroups = response.data.groups;
        this.message = response.data.message;
        this.error = response.error;
        const batches = await LaundryAmountService.getAllBatchesLimit();
        this.batches = batches.data.batches;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    watch: {
      deleteDialog(val) {
        val || this.closeDelete();
      },
    },
    methods: {
      currentType() {
        let types = this.laundryTypes;
        types = types.find((item) => item.id === this.addLaundry.laundryTypeId);
        return types.laundryType;
      },
      deleteItem(item) {
        this.deleteId = Object.assign({}, item);
        this.deleteDialog = true;
      },

      async deleteItemConfirm() {
        console.log(this.deleteId.id);
        try {
          const response = await LaundryAmountService.deleteLaundryBatch(
            this.deleteId.id
          );
          this.message = response.data.message;
          this.error = response.data.error;
          const batches = await LaundryAmountService.getAllBatchesLimit();
          this.batches = batches.data.batches;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
        this.closeDelete();
      },

      closeDelete() {
        this.deleteDialog = false;
      },
      async showCustomers() {
        try {
          const response = await CustomerService.getCustomersByGroupId(
            this.addLaundry
          );
          this.laundryCustomers = response.data.customers;
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      },
      async showLaundryTypes() {
        try {
          const response = await LaundryTypeService.getLaundryTypesByCustomerId(
            this.addLaundry
          );

          this.laundryTypes = response.data.customerItems;
          console.log(this.laundryTypes);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      },

      async addNewLaundryBatch() {
        try {
          const price = await LaundryTypeService.getLaundryTypeById(
            this.addLaundry
          );
          this.addLaundry.laundryAmount = this.addLaundry.laundryAmount.replace(
            ',',
            '.'
          );

          this.addLaundry.laundryPrice = price.data.types[0].laundryPrice;
          this.addLaundry.laundryPriceTotal =
            this.addLaundry.laundryPrice * this.addLaundry.laundryAmount;
          this.addLaundry.type = this.currentType();
          console.log(this.addLaundry);

          const response = await LaundryAmountService.addNewLaundryBatch(
            this.addLaundry
          );
          const batches = await LaundryAmountService.getAllBatchesLimit();
          this.batches = batches.data.batches;
          this.message = response.data.message;
          this.error = response.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      },
    },
  };
</script>
