import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllUsers() {
    return Api().get('/api/users/', {
      // headers: {
      //   Authorization: 'Bearer ' + store.getters.getToken,
      // },
    });
  },
  getMyOrderItems(params) {
    return Api().get('/api/clothes-rental-my-order-items', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveUserModification(credintials) {
    return Api().post('/api/users_', credintials, {
      // headers: {
      //   Authorization: 'Bearer ' + store.getters.getToken,
      // },
    });
  },
  getAllUsersByPermissionLaundry() {
    return Api().get('/api/users/laundry', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
  getAllUsersByPermissions(params) {
    return Api().get('/api/users/permissions', {
      params,
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
