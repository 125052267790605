import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllRentalClothesCustomers() {
    return Api().get('/api/rental-customers', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveRentalClothesCustomerModification(credintials) {
    return Api().post('/api/rental-customer_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addNewRentalClothesCustomer(credintials) {
    console.log(credintials);
    return Api().post('/api/add-rental-customer/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
