<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md8>
        <v-alert :value="true" dense outlined type="error" v-if="error">
          {{ error }}
        </v-alert>
        <v-alert :value="true" dense outlined type="success" v-if="message">
          {{ message }}
        </v-alert>
        <v-form>
          <v-container py-0>
            <v-layout wrap>
              <v-flex xs12 md12 text-left
                ><h4>{{ $t('addLaundryType.laundryTitle') }}</h4></v-flex
              >
              <v-flex xs12 md12>
                <v-text-field
                  :label="$t('addLaundryType.laundryName')"
                  prepend-icon="mdi-adjust"
                  :rules="[rules.required]"
                  v-model="addLaundryTypeValues.laundryName"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-adjust"
                  v-model="addLaundryTypeValues.laundryType"
                  :items="type"
                  item-text="name"
                  item-value="value"
                  :label="$t('addLaundryType.laundryType')"
                  :placeholder="$t('addLaundryType.laundryType')"
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  :label="$t('addLaundryType.laundryPrice')"
                  prepend-icon="mdi-adjust"
                  :rules="[rules.required]"
                  v-model="addLaundryTypeValues.laundryPrice"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs12 md12>
                <v-autocomplete
                  prepend-icon="mdi-traffic-light"
                  v-model="addLaundryTypeValues.laundryState"
                  :items="laundryState"
                  item-text="name"
                  item-value="value"
                  :label="$t('addLaundryType.laundryState')"
                  :placeholder="$t('addLaundryType.laundryAutoCompleteChoise')"
                  required
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 text-xs-right>
                <v-btn
                  class="mx-0 font-weight-light"
                  color="general"
                  @click.prevent="addNewLaundryType"
                >
                  Lisää tuote
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import LaundryTypeService from '@/services/LaundryTypeService.js';
  export default {
    data() {
      return {
        laundryState: [
          {
            name: this.$t('editLaundryType.laundryStateItems.active'),
            value: 'Käytössä',
          },
          {
            name: this.$t('editLaundryType.laundryStateItems.passive'),
            value: 'Pois käytöstä',
          },
        ],
        laundryType: [],
        addLaundryTypeValues: {},
        message: null,
        error: null,
        rules: {
          required: (value) =>
            !!value || this.$t('addLaundryType.laundryRequired'),
        },
        type: [
          { name: 'Kg', value: 1 },
          { name: 'Kpl', value: 2 },
        ],
      };
    },
    async created() {
      try {
        const response = await LaundryTypeService.getAllLaundryTypes();
        this.laundryType = response.data.types;
        this.message = response.data.message;
        this.error = response.error;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
      } catch (error) {
        this.error = error.response.data.error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
    methods: {
      async addNewLaundryType() {
        try {
          this.addLaundryTypeValues.laundryPrice = this.addLaundryTypeValues.laundryPrice.replace(
            ',',
            '.'
          );
          const response = await LaundryTypeService.addNewLaundryType(
            this.addLaundryTypeValues
          );

          this.message = response.data.message;
          this.error = response.error;
          setTimeout(() => {
            this.error = null;
            this.message = null;
          }, 3000);
        } catch (error) {
          this.error = error.response.data.error;
          setTimeout(() => {
            this.error = null;
          }, 3000);
        }
      },
    },
  };
</script>
