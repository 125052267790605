<template>
  <v-data-table
    :search="search"
    :headers="headers"
    :items="customers"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Vuoravaate asiakkaat</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          label="Hae..."
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editRentalClothesCustomer.customerName"
                      label="Asiakkaan nimi"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="
                        editRentalClothesCustomer.customerReferenceNumber
                      "
                      label="Koodi"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      prepend-icon="mdi-account-supervisor"
                      v-model="editRentalClothesCustomer.customerState"
                      :items="states"
                      label="Tila"
                      placeholder="Valitse"
                      item-text="name"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import RentalClothesCustomerService from '@/services/RentalClothesCustomerService.js';

export default {
  data() {
    return {
      customers: [],
      dialog: false,
      error: null,
      search: '',
      message: null,
      formTitle: 'Muokkaa asiakkaan tietoja',
      headers: [
        { text: 'Nimi', value: 'customerName' },
        { text: 'Koodi', value: 'customerReferenceNumber' },
        { text: 'Tila', value: 'customerState' },
        {
          text: 'Muokkaa',
          value: 'actions',
          sortable: false,
        },
      ],
      states: [
        { name: 'Aktiivinen', value: 'Aktiivinen' },
        {
          name: 'Passiivinen',
          value: 'Passiivinen',
        },
      ],
      editRentalClothesCustomer: {
        customerName: '',
        customerState: '',
        customerReferenceNumber: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    const response = await RentalClothesCustomerService.getAllRentalClothesCustomers();
    this.customers = response.data.customers;
  },

  methods: {
    editItem(item) {
      this.editRentalClothesCustomer = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editRentalClothesCustomer = Object.assign({}, this.customers);
      });
    },

    async save() {
      try {
        const response = await RentalClothesCustomerService.saveRentalClothesCustomerModification(
          this.editRentalClothesCustomer
        );
        this.message = response.data.message;
        this.error = response.data.error;
        const customer = await RentalClothesCustomerService.getAllRentalClothesCustomers();
        this.customers = customer.data.customers;
        this.close();
        setTimeout(() => {
          this.message = null;
          this.error = null;
        }, 3000);
      } catch (error) {
        this.close();
        this.error = 'Jokin meni vikaan';
        setTimeout(() => {
          this.error = null;
        });
      }
    },
  },
};
</script>
<style></style>
