import Api from '@/services/Api';
import store from '@/store';

export default {
  getAllClothesToRentalDepartments() {
    return Api().get('/api/clothes-rental-departments/', {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  saveClothesToRentalDepartmentModification(credintials) {
    return Api().post('/api/clothes-rental-department_/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },

  addClothesToRentalDepartment(credintials) {
    return Api().post('/api/clothes-rental-department/', credintials, {
      headers: {
        Authorization: 'Bearer ' + store.getters.getToken,
      },
    });
  },
};

// AuthenticationService.register({
//     email:'testi@testi.fi',
//     passsword: '1234455'
// })
