<template>
  <v-data-table :headers="headers" :items="messages" class="elevation-1">
    <template v-slot:top>
      <v-alert :value="true" dense outlined type="error" v-if="error">
        {{ error }}
      </v-alert>
      <v-alert :value="true" dense outlined type="success" v-if="message">
        {{ message }}
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Viestit</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="showMessage.messageTitle"
                      label="Otsikko"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <vue-editor
                      v-model="showMessage.message"
                      :editorToolbar="customToolbar"
                      placeholder="Kirjoita...."
                      :editorOptions="editorOptions"
                    ></vue-editor>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="user.userRoles === 'super-admin'"
                  >
                    <v-autocomplete
                      v-model="showMessage.messageState"
                      :items="messageStates"
                      label="Viestin tila"
                      placeholder="Viestin tila"
                      item-text="text"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn color="blue darken-1" text @click="close">
                Sulje
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="save">
                Talleta
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.message`]="{ item }">
      <span v-html="item.message"></span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-eye
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import MessageService from '@/services/MessageService.js';
import { VueEditor } from 'vue2-editor';

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      messages: [],
      user: {},
      dialog: false,
      error: null,
      message: null,
      messageStates: [
        { text: 'Uusi', value: 'Uusi' },
        { text: 'Suljettu', value: 'Suljettu' },
      ],
      headers: [
        { text: 'Otsikko', value: 'messageTitle' },
        { text: 'Viesti', value: 'message' },
        { text: 'Tila', value: 'messageState' },
        {
          text: 'Katso',
          value: 'actions',
          sortable: false,
        },
      ],
      showMessage: {
        messageTitle: '',
        message: '',
        messageState: '',
      },
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // ['blockquote', 'code-block'],
        // [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: 'rtl' }], // text direction
        //[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        // ['clean'], // remove formatting button
      ],
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    this.user = this.$store.getters.getUser;
    const response = await MessageService.getAllMessagesUser({ state: 'All' });
    this.messages = response.data.message;
  },

  methods: {
    editItem(item) {
      this.showMessage = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.showMessage = Object.assign({}, this.messages);
      });
    },
    async save() {
      try {
        const response = await MessageService.updateMessage(this.showMessage);
        this.message = response.data.message;
        this.error = response.error;
        this.dialog = false;
        setTimeout(() => {
          this.error = null;
          this.message = null;
        }, 3000);
        const messages = await MessageService.getAllMessagesUser({
          state: 'All',
        });
        this.messages = messages.data.message;
      } catch (error) {
        this.error = error;
        setTimeout(() => {
          this.error = null;
        }, 3000);
      }
    },
  },
};
</script>
<style></style>
